import React, { useState } from "react";
import { DEFAULT_ACTIVE_TAB, IIKO_FILTER_TABS, GET_IIKO_COUNT } from "Constants";
import { useTranslation } from "localization/translation";

export const FilterIikoTable = ({ data, handleFilterProducts }) => {
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);

  const translation = useTranslation();

  const getCount = (name) => {
    const count = GET_IIKO_COUNT[name]({ data });
    return count;
  }

  const handleFilter = (status, id) => {
    handleFilterProducts(status);
    setActiveTab(id)
  }
  return (
    <div className="filter_container">
      {IIKO_FILTER_TABS.map((element) => (
        <button
          className={`element ${activeTab === element.id ? "active_tab" : ""}`}
          key={element.id}
          onClick={() => handleFilter(element.status, element.id)}
        >
          {translation[element.name]}
          <span className="product_count">{getCount(element.count_name)}</span>
        </button>
      ))}
    </div>
  );
};