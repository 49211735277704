import React from "react"
import styles from "./styles.module.scss"

const ProcessStep = ({ stepNumber, title, description }) => {
    return (
        <div className={styles.main_content}>
            <div>
              <p className={styles.stepNumber}>{stepNumber}</p>
            </div>
            <div className={styles.texts}>
                <h5 className={styles.title}>{title}</h5>
                <p className={styles.description}>{description}</p>
            </div>
        </div>
    )
}

export default ProcessStep
