import { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";

import { PAGE } from "Constants";
import { SEARCH } from "Constants";

export const useGetList = (Api, query, limit = 20, hideSearch) => {
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState();
  const [total, setTotal] = useState(null);
  const [remainingBranches, setRemainingBranches] = useState(0);

  const queryUrl = new URLSearchParams(useLocation().search);

  const params = {
    page: (queryUrl.get(PAGE) || "1") - 1,
    limit: limit,
    ...(query && query),
  };

  if (!hideSearch) params.search = queryUrl.get(SEARCH);

  const callback = useCallback(() => {
    if (Api) {
      setLoading(true);
      Api(params)
        .then((res) => {
          setItems(res.data);
          setTotal(res.total);
          setRemainingBranches(res.remainingBranchesCount);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [Api, query, queryUrl]);

  useEffect(callback, [Api, queryUrl.toString(), query]);

  return [items, total, callback, setItems, remainingBranches];
};
