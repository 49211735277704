import React from "react";
import LoginWrapperContainer from "../../layout-components/LoginWrapperContainer";
import { SetPassword } from "components";
import * as Api from '../../service';
import useStore from "../../context";

const t = {
  title_en: 'Please enter your password',
  title_ru: 'Пожалуйста, введите пароль',
  title_hy: 'Խնդրում ենք մուտքագրել ձեր գաղտնաբառը',
  sub_title_en: 'Please create your password',
  sub_title_ru: 'Пожалуйста, создайте пароль',
  sub_title_hy: 'Խնդրում ենք ստեղծել ձեր գաղտնաբառը',
  text_en: 'Set Password',
  text_ru: 'Установить пароль',
  text_hy: 'Սահմանել գաղտնաբառ',
};

const Continue = () => {
  const { language } = useStore();
  const setPassword = async ({ token, data }) => {
    return await Api.sign.clientContinue({ token, ...data })
  }
  return (
    <LoginWrapperContainer>
      <SetPassword
        title={t[`title_${language}`]}
        subTitle={t[`sub_title_${language}`]}
        request={setPassword}
        buttonText={t[`text_${language}`]}
      />
    </LoginWrapperContainer>
  )
};

export default Continue;
