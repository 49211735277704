export const MANAGER_ROLE = "manager";
export const ERROR_MESSAGE = "All fields are required";
export const OPEN_STREET_MAP_URL = "https://nominatim.openstreetmap.org/";
export const WEIGHT_ERROR = "The weight is too big";
export const VOLUME_ERROR = "The volume is too big";
export const MEASUREMENT_UNITS = {
  litrage: 1,
  kilogram: 4,
  pieces: 3,
};
