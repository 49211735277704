import React from "react";

export const InfoSection = ({ title, name }) => {
  return (
    <p className="info_text">
      {title} {" : "}
        <span className="weight_light">
          {name}
        </span>
    </p>
  );
};