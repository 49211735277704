import { localizationArmenian } from "./localizationArmenian";
import { localizationEnglish } from "./localizationEnglish";
import { localizationRussian } from "./localizationRussian";
import useStore from "context";

const localization = {
  hy: localizationArmenian,
  en: localizationEnglish,
  ru: localizationRussian,
}

export const useTranslation = () => {
  const { language } = useStore();
  return localization[language]
};
