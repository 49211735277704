import React from "react";
import { useLocation } from "react-router-dom";
import HeaderUserbox from "layout-components/HeaderUserbox";

import clsx from "clsx";

import { connect } from "react-redux";

import { setSidebarToggleMobile } from "reducers/ThemeOptions";

import HeaderSearch from "layout-components/HeaderSearch";
import { CREATE, EDIT, NOT_SEARCHABLE_PAGES_PATHS } from "Constants";

const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile,
  } = props;

  const localtion = useLocation();

  const hideSearch =
    localtion.pathname.includes(CREATE) ||
    localtion.pathname.includes(EDIT) ||
    NOT_SEARCHABLE_PAGES_PATHS.includes(localtion.pathname);

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div
        className={clsx("app-header", {
          "app-header--shadow": headerShadow,
          "app-header--opacity-bg": headerBgTransparent,
        })}
      >
        <div
          style={{
            width: "100%",
          }}
          className="app-header--pane w100"
        >
          <button
            className={clsx(
              "navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn",
              { "is-active": sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          {!hideSearch && <HeaderSearch />}
          <div
            style={{
              marginLeft: "auto",
            }}
            className="text-center d-flex"
          >
            <HeaderUserbox />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
