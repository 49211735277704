import React, { useCallback, useState } from "react";
import styles from "./styles.module.scss";
import Input from "components/login-input";
import { useTextFront } from "hooks";
import { useHistory } from "react-router-dom";
import * as Api from "service";
import { ReactComponent as BackArrow } from "assets/images/backArrow.svg";
import { ReactComponent as CheckRight } from "assets/images/checkRight.svg";
import { isValidField, isValidForm } from "helpers/formValidation";
import { useTranslation } from "localization/translation";
import { EMAIL, TEXT, SUCCESS_EMAIL, HEADER_URLS } from "Constants";
import Header from "components/header";

const initForm = {
  email: "",
};

const validation = {
  email: "email:required",
};

const ForgetPassword = () => {
  const history = useHistory();
  const translation = useTranslation();
  const [text, setText, , input] = useTextFront(initForm);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [fieldError, setFieldError] = useState({});

  const submit = useCallback(
    async (e) => {
      e.preventDefault();
      setIsSubmitted(true);
      if (isValidForm({ text, validation, setFieldError })) {
        try {
          setSuccess("");
          setError("");
          await Api.sign.resetPassword(text);
          setSuccess(SUCCESS_EMAIL);
          setText(initForm);
        } catch (err) {
          setError(err.message);
        }
      }
    },
    [text]
  );

  return (
    <section className={styles.signSection}>
      <Header url={HEADER_URLS.forget_password} />
      <div className={styles.mainContainer}>
        <div className={styles.titleSection}>
          <BackArrow onClick={() => history.goBack()} />
          <h2>{translation.forgot_password}</h2>
        </div>
        <p>{translation.help_reset_password}</p>

        {success ? (
          <div className={styles.success}>
            <CheckRight />
            <p>{translation.have_account}</p>
          </div>
        ) : (
          <form onSubmit={submit}>
            <div className={styles.singByMail}>
              <Input
                {...input.email}
                error={
                  isSubmitted &&
                  isValidField({
                    value: text.email,
                    type: EMAIL,
                    required: true,
                  })
                }
                helpText={
                  isValidField({
                    value: text.email,
                    type: EMAIL,
                    required: true,
                  }) && fieldError?.email
                }
                type={TEXT}
                placeholder={translation.email_address}
              />
              <span className={styles.error}>{error}</span>
              <button
                type="submit"
                className="submit-btn"
                disabled={!text.email}
                style={{ opacity: !text.email && 0.5 }}
              >
                {translation.reset_password}
              </button>
            </div>
          </form>
        )}
      </div>
    </section>
  );
};

export default ForgetPassword;
