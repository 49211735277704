import React, { useState } from 'react';
import { WrapperSeamless } from '../../../layout-components';
import Table from '../../../components/tables/table';
import { useGetList } from '../../../hooks';
import * as Api from '../../../service';
import { useTranslation } from 'localization/translation';



export default function List() {
  const translation = useTranslation();
  const [query, setQuery] = useState({
    sort: 'created_at',
    order: 'desc',
  });


  const [items, total, , setItems] = useGetList(
    Api.requestBranches.getAll,
    query
  );


    const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.branch_count}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.message}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm text-capitalize">{translation[item.status]}</div>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.requested_branches_count}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.message}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.status}</span>
            </th>
          </>
        }
        actions={false}
        noDrag
        title={translation.branch_requests}
        items={items}
        setItems={setItems}
        total={total}
        rows={rows}
        createLink="dashboard/request-branches/create"
      />
    </WrapperSeamless>
  );
}
