import React, { useState } from "react";
import { WrapperSeamless } from "layout-components";
import Table from "components/tables/table";
import { useGetList } from "hooks";
import * as Api from "service";
import useStore from "context";
import { useTranslation } from "localization/translation";
import { ActionsDropdown, ActiveDeactivatedConfirm } from "components";

export default function List() {
  const { setErrorMsg } = useStore();
  const [isOpenAction, setIsOpenAction] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [branchId, setBranchId] = useState(null);
  const [isActiveBranch, setIsActiveBranch] = useState(null);
  const { setRemove } = useStore();
  const translation = useTranslation();
  const [query, setQuery] = useState({
    sort: "created_at",
    order: "desc",
  });

  const [items, total, refresh, setItems, remainingBranches] = useGetList(
    Api.branches.getAll,
    query
  );

  const getStatusName = (status) => {
    return !!status ? translation.active : translation.deactivated;
  };

  const deleteBranch = (id) => {
    setRemove(() => () => Api.branches.delete(id).then(refresh));
  };

  const changeStatus = () => {
    Api.main
      .setUserBranchAction({
        id: branchId,
        data: { status: isActiveBranch },
      })
      .then(() => {
        refresh();
      })
      .catch((err) => {
        setErrorMsg(err.message);
      })
      .finally(() => setIsOpenAction(false));
  };

  const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.name}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.phone}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.address}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.latitude}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.longitude}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{getStatusName(item?.status)}</div>
      </td>
      <td className="text-right">
        <div className="d-flex">
          <ActionsDropdown
            status={item.status}
            editUrl={`/dashboard/branches/create/${item.id}`}
            handleDelete={deleteBranch}
            id={item.id}
            setActionType={setActionType}
            setBranchId={setBranchId}
            setIsActiveBranch={setIsActiveBranch}
            setIsOpenAction={setIsOpenAction}
          />
        </div>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <ActiveDeactivatedConfirm
        setIsOpenAction={setIsOpenAction}
        isOpenAction={isOpenAction}
        onClickHandler={changeStatus}
        actionType={actionType}
        isActiveBranch={isActiveBranch}
        name={translation.branch}
      />
      <Table
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{translation.name}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.phone}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.address}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.latitude}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.longitude}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.status}</span>
            </th>
          </>
        }
        noDrag
        title={translation.items}
        items={items}
        setItems={setItems}
        total={total}
        rows={rows}
        createLink="dashboard/branches/create"
        remainingBranches={remainingBranches}
      />
    </WrapperSeamless>
  );
}
