import React from "react";
import { Link } from "react-router-dom";
import { Button, Dialog} from "@material-ui/core";
import { useTranslation } from "localization/translation";
import { ROUTES } from "Constants";

export const UnresolvedModal = ({
  setOpen,
  open,
  unresolvedByUnit,
  unresolved,
  unresolvedGoodsId,
  unresolvedByUnitGoodsId
}) => {
  const translation = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: "shadow-lg rounded" }}
    >
      <div className="text-center p-5 width_500">
        <h4 className="font-weight-bold mt-4 capitalize">{translation.successfully_sync}</h4>
        <div className="select_store">
          <div className="iiko_unresolved_message">
            {!!unresolved &&
              <span>{`${translation.you_have} ${unresolved} `}
              <Link
                  to={{
                    pathname: ROUTES.iiko_unresolved,
                    state: {
                      iiko: unresolvedGoodsId,
                    }
                  }}
                >
                   <span className="unresolved_products">{translation.unresolved_goods}</span>
                </Link>
              </span>
            }
            {!!unresolvedByUnit &&
              <span>{`${translation.you_have_unresolved} ${unresolvedByUnit} `}
              <Link
                  to={{
                    pathname: ROUTES.iiko_unresolved,
                    state: {
                      iiko: unresolvedByUnitGoodsId,
                    }
                  }}
                >
                   <span className="unresolved_products">{translation.unresolved_unit_goods}</span>
                </Link>
              </span>
            }
          </div>
        </div>
        <div className="pt-4">
          <Button
            onClick={() => setOpen(false)}
            className="btn-neutral-primary btn-pill mx-1"
            color="primary"
          >
            <span className="btn-wrapper--label">{translation.ok}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};