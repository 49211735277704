import React, { useState } from "react"
import styles from "./styles.module.scss"
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg"

const Question = ({ title, content }) => {
    const [open, setOpen] = useState(false)
    return (
        <div
          className={`${open ? styles.question_open : styles.question_close} ${styles.question}`}
          onClick={() => setOpen((prev) => !prev)}
        >
            <div className={styles.header}>
                <h4 className={styles.title}>
                    {title}
                </h4>
                <div className='plusIcon_wrapper'>
                    <PlusIcon className={!open ? styles.open_icon : styles.close_icon} />
                </div>
            </div>
            <div className={open ? styles.content_visible : styles.content}>
                <p className={open ? styles.visible_text : styles.unvisible_text}>
                    {content}
                </p>
            </div>
        </div>
    )
}

export default Question
