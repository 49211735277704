import React from "react";

import styles from "./styles.module.scss";

const TitleSubtitle = ({
  title,
  subtitle,
  text,
  subtitleClassName,
  textClassName,
}) => {
  return (
    <div className={styles.main_section}>
      {title && <p className={styles.title}>{title}</p>}
      <h1 className={subtitleClassName}>{subtitle}</h1>
      {text && <p className={textClassName}>{text}</p>}
    </div>
  );
};

export default TitleSubtitle;
