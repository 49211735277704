/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";

import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

import { useTranslation } from "localization/translation";
import { FIRST_PAGE, DELAY } from "Constants";

const useStyles = makeStyles({
  button: {
    background: "#22BB33",
    color: "white",
    "&:hover": {
      background: "#1ba52a",
    },
  }
})

export const IikoUnsolvedHeader = ({
  setIikoQuery,
  setClientQuery,
  handleMerge
}) => {
  const [searchIiko, setSearchIiko] = useState("");
  const [searchGood, setSearchGood] = useState("");
  const buttonClasses = useStyles();

  const iikoInput = document.getElementById("iiko-search-input");
  const goodsInput = document.getElementById("good-search-input");

  const translation = useTranslation();

  let typingTimerForGoods;
  let typingTimerForIiko;

  const changeGoodSearchQuery = useCallback((event) => {
    clearTimeout(typingTimerForGoods);
    typingTimerForGoods = setTimeout(() => {
      setClientQuery((prev) => ({
        ...prev,
        search: event.target.value,
        page: FIRST_PAGE
      }))
    }, DELAY);
  }, [])

  const changeIikoSearchQuery = useCallback((event) => {
    clearTimeout(typingTimerForIiko);
    typingTimerForIiko = setTimeout(() => {
      setIikoQuery((prev) => ({
        ...prev,
        search: event.target.value,
        page: FIRST_PAGE
      }))
    }, DELAY);
  }, [])

  const handleSearchIiko = (e) => {
    if(e.target.value !== searchIiko) {
      setSearchIiko(e.target.value);
      iikoInput.addEventListener("input", changeIikoSearchQuery);
    }
  }

  const handleSearchGood = (e) => {
    if(e.target.value !== searchGood) {
      setSearchGood(e.target.value);
      goodsInput.addEventListener("input", changeGoodSearchQuery);
    }
  }

  return (
    <div className="iiko_unsolved_header">
      <div className="search_filter_field">
        <TextField
          type="search"
          id="iiko-search-input"
          name="iiko-search-input"
          placeholder={translation.search_iiko}
          variant="outlined"
          size="small"
          value={searchIiko}
          onChange={handleSearchIiko}
        />
      </div>
      <div>
        <Button
          variant="contained"
          className={buttonClasses.button}
          onClick={handleMerge}
        >
          {translation.merge}
        </Button>
      </div>
      <div className="search_filter_field">
        <TextField
          type="search"
          id="good-search-input"
          name="good-search-input"
          placeholder={translation.search_good}
          variant="outlined"
          size="small"
          value={searchGood}
          onChange={handleSearchGood}
        />
      </div>
    </div>
  );
};