import React, { useEffect, useState } from "react";
import { ChangeSettings } from "./elementSettings";
import { useTranslation } from "localization/translation";
import useStore from "context";
import { GRAM, KILOGRAM, LITER, MILLILITER, SUCCESS_MESSAGE, VOLUME, WEIGHT } from "Constants";
import { usePutData } from "hooks";
import * as Api from "service";

const updateRoute = false;
const dontShowMsg = true;

const Settings = () => {
  const { weightValue, volumeValue, refresh, setSuccessMsg } = useStore();

  const [weight, setWeight] = useState(weightValue);
  const [volume, setVolume] = useState(volumeValue);

  const updateCallback = usePutData(Api.settings.update, refresh, updateRoute, dontShowMsg);

  const translation = useTranslation();

  const handleChangeWeight = (value) => {
    setWeight(value);
  }

  const handleChangeVolume = (value) => {
    setVolume(value);
  }

  const handleSubmit = () => {
    let hasChange = false;
    if(weight !== weightValue) {
      hasChange = true;
      const data = {
        key: WEIGHT,
        value: weight,
      }
      updateCallback(data);
    }
    if(volume !== volumeValue) {
      hasChange = true;
      const data = {
        key: VOLUME,
        value: volume,
      }
      updateCallback(data);
    }

    if (hasChange) {
      setSuccessMsg(SUCCESS_MESSAGE);
    }
  }

  useEffect(() => {
    setWeight(weightValue);
  }, [weightValue])

  useEffect(() => {
    setVolume(volumeValue);
  }, [volumeValue])

  return (
    <div className="settings">
      <div className="settings_container">
        <h2 className="settings_title">{translation.settings_title}</h2>
        <ChangeSettings
          title={translation.adjust_weight}
          selected={weight !== KILOGRAM}
          value={KILOGRAM}
          valueMill={GRAM}
          handleChange={handleChangeWeight}
          massName={translation.kilogram}
          microMassName={translation.gram}
        />
        <ChangeSettings
          title={translation.adjust_volume}
          selected={volume === MILLILITER}
          value={LITER}
          valueMill={MILLILITER}
          handleChange={handleChangeVolume}
          massName={translation.liter}
          microMassName={translation.milliliter}
        />
        <button className="save_btn" onClick={handleSubmit} >{translation.save}</button>
      </div>
    </div>
  );
};

export default Settings;
