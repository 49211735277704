import React, { useState } from 'react';
import { WrapperSeamless } from '../../../layout-components';
import Table from '../../../components/tables/table';
import { Button } from '@material-ui/core';
import { useGetList } from '../../../hooks';
import * as Api from '../../../service';
import useStore from '../../../context';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'localization/translation';

export default function List() {
  const { setRemove } = useStore();
  const translation = useTranslation();
  const [query, setQuery] = useState({
    sort: 'created_at',
    order: 'desc',
  });


  const [items, total, refresh, setItems] = useGetList(
    Api.managerInvitations.getAll,
    query
  );

    const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.name}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.surname}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.phone}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.email}</div>
      </td>
      <td className="text-left px-4">
        <div style={{textTransform: 'capitalize'}} className="font-size-sm">{translation[item.status]}</div>
      </td>
      <td className="text-right">

        <Button
          onClick={() =>
            setRemove(() => () =>
              Api.managerInvitations.delete(item.id).then(refresh)
            )
          }
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
          <DeleteIcon color="error" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.first_name}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.last_name}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.phone}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.email}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.status}</span>
            </th>
          </>
        }
        noDrag
        title={translation.items}
        items={items}
        setItems={setItems}
        total={total}
        rows={rows}
        createLink="dashboard/manager-invitations/create"
      />
    </WrapperSeamless>
  );
}
