import React from "react";
import { Link } from 'react-router-dom';

import { Button, TextField } from "@material-ui/core";
import { useTranslation } from "localization/translation";
import { FilterIikoTable } from "./filter";

export const IikoHeader = ({
  isUnresolved,
  handleSync,
  showSearch,
  handleSearch,
  search,
  showFilterBar,
  data,
  total,
  handleFilterProducts,
  disableSync
}) => {
  const translation = useTranslation();

  return (
    <div>
      <div className="iiko_header">
        {showSearch && (
          <div className="search_filter_field">
            <TextField
              type="search"
              id="iiko-products-search-input"
              name="iiko-search-input"
              placeholder={translation.search_terms}
              variant="outlined"
              size="small"
              value={search}
              onChange={handleSearch}
            />
          </div>
        )}
        <div className="buttons_filed">
          {isUnresolved &&
            <Link to="/dashboard/iiko-unresolved">
              <Button variant="contained" color="secondary">{translation.resolve_goods}</Button>
            </Link>
          }
          <Button
            variant="contained"
            color="primary"
            onClick={handleSync}
            disabled={disableSync}
          >
            {translation.sync}
          </Button>
        </div>
      </div>
      {showFilterBar && (
        <FilterIikoTable
          data={data}
          total={total}
          handleFilterProducts={handleFilterProducts}
        />
      )}
    </div>
  )
}