import React, { useCallback, useEffect, useState } from "react";

import { Box, Card, Container, TextField } from "@material-ui/core";

import { Save } from "components";
import { useText } from "hooks";
import useStore from "context";
import { useTranslation } from 'localization/translation';
import Gallery from "components/gallery";
import * as Api from "service";
import {useHistory} from "react-router-dom";

const initForm = {
  company_name: "",
  business_address: "",
  phoneNumber: "",
  name: "",
  surname: "",
  email: "",
  image: "",
};

export const EditProfile = () => {
  const history = useHistory();
  const translation = useTranslation();
  const [newFiles, setNewFiles] = useState(null);
  const [labelName, setLabelName] = useState("Details");
  const [data, setData] = useState();

  const { user, setSuccessMsg, setErrorMsg } = useStore();

  const [text, setText, textChange, input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      const formData = new FormData();
      if (newFiles) {
        formData.append("file", newFiles[0]);
      }

      const link = newFiles
        ? await Api.main.uploadFile(formData).then((res) => res.filePath)
        : text.image;

      const data = {
        ...text,
        image: link,
      };

      const filteredData = {};
      for(let key in data) {
        if(data[key]) filteredData[key] = data[key];
      }

      try {
        await Api.main.editProfile(filteredData);
        setText(data);
        setSuccessMsg("Updated");
        history.go(-1);
      } catch {
        setErrorMsg(e.message || "There is an error");
      }
    },
    [text, newFiles]
  );

  const getProfile = async () => {
    try {
      const res = await Api.main.getProfile();
      setData(res.user);
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    getProfile();
  },[])

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Gallery
            single
            label={labelName}
            setNewFile={setNewFiles}
            value={text.image ? text.image : null}
            setImages={(img) => setText((st) => ({ ...st, image: img }))}
            onChange={() => setText((st) => ({ ...st, image: "" }))}
            setLabelName={setLabelName}
          />
          {user?.role === "customer" && (
            <>
              <TextField
                InputProps={{ required: true }}
                type="text"
                {...input("company_name")}
                label={translation.company_name}
              />
              <TextField
                InputProps={{ required: true }}
                type="text"
                {...input("business_address")}
                label="Company address"
              />
            </>
          )}
          <TextField
            InputProps={{ required: true }}
            type="text"
            {...input("name")}
            label={translation.first_name}
          />
          <TextField
            InputProps={{ required: true }}
            type="text"
            {...input("surname")}
            label={translation.last_name}
          />
          <TextField
            InputProps={{ required: true }}
            type="email"
            {...input("email")}
            disabled
            label={translation.email}
          />
          <TextField
            InputProps={{ required: true }}
            type="text"
            {...input("phoneNumber")}
            label={translation.phone_number}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};
