import { useCallback } from "react";
import useStore from "../context";
import { useHistory } from "react-router-dom";
import { useTranslation } from "localization/translation";

export const usePostData = (Api, refresh, isOrder) => {
  const history = useHistory();
  const translation = useTranslation();

  const { setSuccessMsg, setErrorMsg, errorMsg, language } = useStore();

  return useCallback(
    async (data) => {
      try {
        await Api(data);
        if (typeof refresh !== "undefined") {
          refresh();
        }
        setSuccessMsg(isOrder ? translation.order_changed : translation.added);
        if (!isOrder) history.goBack();
      } catch (e) {
        setErrorMsg(errorMsg || e.message || "There is an error");
      }
    },
    [Api, history, refresh, setErrorMsg, setSuccessMsg, language]
  );
};
