import React from "react";
import { Button, Dialog } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "localization/translation";
import { INACTIVE_STATUS } from "Constants";

export const ActiveDeactivatedConfirm = ({
  setIsOpenAction,
  isOpenAction,
  onClickHandler,
  actionType,
  name,
}) => {
  const translation = useTranslation();

  const message = `${
    actionType === INACTIVE_STATUS
      ? translation.deactivate
      : translation.activate
  } ${name}`.toLowerCase();

  return (
    <Dialog
      open={isOpenAction}
      onClose={() => setIsOpenAction(false)}
      classes={{ paper: "shadow-lg rounded" }}
    >
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div
            className={`d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper ${
              actionType === INACTIVE_STATUS
                ? "text-danger bg-neutral-danger"
                : "text-success bg-neutral-success"
            } m-0 d-130`}
          >
            {actionType === INACTIVE_STATUS ? (
              <FontAwesomeIcon
                icon={["fas", "times"]}
                className="d-flex align-self-center display-3"
              />
            ) : (
              <FontAwesomeIcon
                icon={["fas", "check"]}
                className="d-flex align-self-center display-3"
              />
            )}
          </div>
        </div>
        <h4
          className={`font-weight-bold mt-4`}
        >{`${translation.are_you_sure_you_want_to}${message}`}</h4>
        <div className="pt-4">
          <Button
            onClick={() => setIsOpenAction(false)}
            className="btn-neutral-secondary btn-pill mx-1"
          >
            <span className="btn-wrapper--label">{translation.cancel}</span>
          </Button>
          <Button
            onClick={onClickHandler}
            className={`${
              actionType === INACTIVE_STATUS ? "btn-danger" : "btn-success"
            } btn-pill mx-1 ${actionType}`}
          >
            <span className="btn-wrapper--label btn-action text-capitalize">
              {actionType === INACTIVE_STATUS
                ? translation.deactivate
                : translation.activate}
            </span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
