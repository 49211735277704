import React from "react";
import LoginWrapperContainer from "../../layout-components/LoginWrapperContainer";
import { SignIn } from "components";

const SignInPage = () => {
  return (
    <LoginWrapperContainer>
      <SignIn />
    </LoginWrapperContainer>
  )
};

export default SignInPage;
