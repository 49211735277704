import React, { useState, useCallback, useEffect } from 'react';

import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@material-ui/core';

import { connect } from 'react-redux';

import { setHeaderSearchHover } from '../../reducers/ThemeOptions';
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'localization/translation';

const HeaderSearch = (props) => {
  const [search, setSearch] = useState('');
  const history = useHistory()
  const { headerSearchHover, setHeaderSearchHover } = props;
  const translation = useTranslation();

  const { pathname } = useLocation();

  const query = new URLSearchParams(useLocation().search);
  const updateSearch = useCallback(e => {
    e.preventDefault()
    query.set('search', search)
    query.set('page', 1)
    history.push(`?${query.toString()}`)
  }, [history, query, search]);

  const toggleHeaderSearchHover = () => {
    setHeaderSearchHover(!headerSearchHover);
  };
  useEffect(() => {
      setSearch(query.get("search") ?? "");
  }, [pathname])

  return (
    <form onSubmit={updateSearch} className="header-search-wrapper">
      <div
        className={clsx('search-wrapper', {
          'is-active': headerSearchHover
        })}>
        <label className="icon-wrapper" htmlFor="header-search-input">
          <FontAwesomeIcon icon={['fas', 'search']} />
        </label>
        <TextField
          onFocus={toggleHeaderSearchHover}
          onBlur={toggleHeaderSearchHover}
          value={search}
          onChange={e => setSearch(e.target.value)}
          id="header-search-input"
          name="header-search-input"
          type="search"
          placeholder={translation.search_terms}
          variant="outlined"
        />
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  headerSearchHover: state.ThemeOptions.headerSearchHover
});

const mapDispatchToProps = (dispatch) => ({
  setHeaderSearchHover: (enable) => dispatch(setHeaderSearchHover(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch);
