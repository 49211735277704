import React from "react";
import { Header, LoginBackground } from 'components';
import styles from './styles.module.scss'

const LoginWrapperContainer = ({ children }) => {
  return (
    <div className={styles.container}>
      <LoginBackground />
      <div className={styles.flexSection}>
        <Header url="home" display="none" />
        {children}
      </div>
    </div>
  )
};

export default LoginWrapperContainer;
