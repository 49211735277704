import React from "react";
import useStore from 'context';
import { getMeasurementFullName } from "helpers/getMeasurementName";
import { useTranslation } from "localization/translation";
import { InfoSection } from "./element-info";


export const TableElement = ({
  handleSelectElement,
  activeClass,
  id,
  unit,
  name,
  barcode,
  showBorder
}) => {
  const { language, weightValue, volumeValue } = useStore();

  const translation = useTranslation();

  return (
    <div
      className={`element ${activeClass} ${showBorder ? "show_border" : "" }`}
      onClick={() =>
        handleSelectElement(id, unit)
      }
    >
      <div className="info_section">
        <InfoSection title={translation.name} name={name} />
        <div className="info_row">
          <InfoSection title={translation.barcode} name={barcode} />
          <InfoSection
            title={translation.unit}
            name={getMeasurementFullName(unit, language, weightValue, volumeValue)}
          />
        </div>
      </div>
      <div>

      </div>
    </div>
  );
};