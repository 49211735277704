import React from "react"
import styles from "./styles.module.scss"
import QuantitySliderItem from "../quantity-slider-item"

const QuantitySlider = ({ data, lang }) => {
    return (
        <div className={styles.main}>
            <QuantitySliderItem
              subtitle={data?.[`item1_title_${lang}`]}
              text={data?.[`item1_description_${lang}`]}
              icon={data?.item1_icon}
            />
            <QuantitySliderItem
              subtitle={data?.[`item2_title_${lang}`]}
              text={data?.[`item2_description_${lang}`]}
              icon={data?.item2_icon}
            />
            <QuantitySliderItem
              subtitle={data?.[`item3_title_${lang}`]}
              text={data?.[`item3_description_${lang}`]}
              icon={data?.item3_icon}
            />
        </div>
    )
}

export default QuantitySlider
