import React, { useCallback } from "react";
import {
  Container,
  TextField,
  Card,
  Box,
  InputAdornment,
} from "@material-ui/core";

import { useText, usePutData } from "hooks";
import useStore from "context";

import * as Api from "service";
import { Save } from "components";
import LockTwoToneIcon from "@material-ui/icons/LockTwoTone";

import {
  OLD_PASSWORD,
  PASSWORD,
  CONFIRMATION_PASSWORD,
  PASSWORD_ERROR_MESSAGE,
  MIN_LENGTH_PASSWORD,
} from "Constants";
import { getError } from "helpers/formValidation";
import { useTranslation } from "localization/translation";

const initForm = {
  old_password: "",
  password: "",
  password_confirmation: "",
};

const Form = () => {
  const updateCallback = usePutData(Api.main.updatePassword);
  const { setErrorMsg } = useStore();
  const [text, setText, textChange, input] = useText(initForm);

  const translation = useTranslation();

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      if (text.password.length < MIN_LENGTH_PASSWORD) {
        return setErrorMsg(PASSWORD_ERROR_MESSAGE);
      }
      try {
        await updateCallback(text);
      } catch (err) {
        if (getError(err)) {
          setErrorMsg(getError(err));
        }
      }
      setText(initForm);
    },
    [setText, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type={PASSWORD}
            {...input(OLD_PASSWORD)}
            label={translation.current_password}
          />
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type={PASSWORD}
            {...input(PASSWORD)}
            label={translation.new_password}
          />
          <TextField
            InputProps={{
              required: true,
              startAdornment: (
                <InputAdornment position="start">
                  <LockTwoToneIcon />
                </InputAdornment>
              ),
            }}
            type={PASSWORD}
            {...input(CONFIRMATION_PASSWORD)}
            label={translation.confirm_password}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
