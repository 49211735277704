import { useState, useRef, useEffect } from "react";

import { SCROLL_DOWN, SCROLL_UP } from "Constants";

const BOUND_HEIGHT = 26;
const BOUND_HEIGHT_TOP = 50;
const STABLE = "stable";
const TOP = "top";
const BOTTOM = "bottom";

function getScrollDirection({ position = undefined, bounds = undefined }) {
  if (position === undefined) {
    return STABLE;
  }
  if (position > bounds - BOUND_HEIGHT) {
    return BOTTOM;
  }
  if (position < BOUND_HEIGHT_TOP) {
    return TOP;
  }
  return STABLE;
}

export const useScroll = () => {
  const [config, setConfig] = useState({
    position: 0,
    isScrollAllowed: false,
  });
  const [update, setUpdate] = useState({ monitor: null });
  const scrollTimer = useRef(null);
  const scrollSpeed = 2;
  const { position, isScrollAllowed } = config;
  const bounds = window.innerHeight;

  const internal = update?.monitor;
  const direction = getScrollDirection({
    position,
    bounds: bounds,
  });

  useEffect(() => {
    if (direction !== STABLE && isScrollAllowed) {
      scrollTimer.current = setInterval(() => {
        return window?.scrollBy(0, scrollSpeed * (direction === TOP ? SCROLL_UP : SCROLL_DOWN));
      }, 1);
    }
    return () => {
      if (scrollTimer.current) {
        clearInterval(scrollTimer.current);
      }
    };
  }, [isScrollAllowed, direction, scrollSpeed]);

  useEffect(() => {
    if (internal) {
      const unsubscribe = internal.subscribeToOffsetChange(() => {
        const offset = internal.getSourceClientOffset()?.y;
        setConfig({ position: offset, isScrollAllowed: true });
      });
      return unsubscribe;
    }
  }, [internal]);

  return { updatePosition: setUpdate };
};
