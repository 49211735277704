import React, {useEffect, useState} from "react"
import TitleSubtitle from "components/titlie-subtitle"
import Question from "./components/question"
import styles from "./styles.module.scss"
import useStore from "../../context";
import * as Api from "../../service";
import { useTranslation } from 'localization/translation';

const FAQ = () => {
  const { language } = useStore();
  const [faqSection, setFAQSection] = useState([]);
  const translation = useTranslation();

    const getFAQ = () => {
        Api.main.getFAQSection()
          .then(({ data }) => {
              setFAQSection(data);
          })
    };

    useEffect(() => {
        getFAQ();
    },[]);
    return (
        <div className={styles.faq} id="faq">
            <div className={styles.title}>
                <TitleSubtitle
                    title={translation.for_trackers}
                    subtitle={translation.faq_title}
                    subtitleClassName={styles.subtitle}
                />
            </div>
            <div className={styles.question_section}>
                {
                    faqSection?.map((el) => {
                        if (el?.[`question_${language}`]) {
                            return (
                              <Question
                                key={el.id}
                                title={el?.[`question_${language}`]}
                                content={el?.[`answer_${language}`]}
                              />
                            )
                        }
                    })
                }
            </div>
        </div>
    )
}

export default FAQ
