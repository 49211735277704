import React from "react";
import LoginWrapperContainer from "../../layout-components/LoginWrapperContainer";
import { SetPassword } from "components";
import * as Api from "../../service";
import useStore from "../../context";

const t = {
  title_en: "Please enter your new password",
  title_ru: "Пожалуйста, введите новый пароль",
  title_hy: "Խնդրում ենք մուտքագրել ձեր նոր գաղտնաբառը",
  sub_title_en: "Please create your new password",
  sub_title_ru: "Пожалуйста, создайте новый пароль",
  sub_title_hy: "Խնդրում ենք ստեղծել ձեր նոր գաղտնաբառը",
  text_en: "Set New Password",
  text_ru: "Установить новый пароль",
  text_hy: "Սահմանել նոր գաղտնաբառ",
};

const ResetPassword = () => {
  const { language } = useStore();
  const setPassword = async ({ token, data, email = null }) => {
    const body = {
      ...data,
    };
    if (email) {
      body.email = email;
    }
    return await Api.sign.resetPasswordConfirm(token, { ...body });
  };
  return (
    <LoginWrapperContainer>
      <SetPassword
        title={t[`title_${language}`]}
        subTitle={t[`sub_title_${language}`]}
        request={setPassword}
        buttonText={t[`text_${language}`]}
      />
    </LoginWrapperContainer>
  );
};

export default ResetPassword;
