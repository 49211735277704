import queryString from 'query-string';
import jwt from 'jsonwebtoken'
import * as Api from '../service';
import {win} from "leaflet/src/core/Browser";
import { LANGUAGE } from 'Constants';


class Base {
  getRequest(uri, query) {
    return this.baseRequest('GET', uri, null, query);
  }
  postRequest(...rest) {
    return this.baseRequest('POST', ...rest);
  }
  deleteRequest(...rest) {
    return this.baseRequest('DELETE', ...rest);
  }
  putRequest(...rest) {
    return this.baseRequest('PUT', ...rest);
  }

  async baseRequest(type, uri, data = null, query = null) {
    const queryUrl = query ? `?${queryString.stringify(query, {
      skipEmptyString: true,
      skipNull: true
    })}` : '';

    const language = localStorage.getItem(LANGUAGE);

    const fullUrl = process.env.REACT_APP_apiBaseUrl + uri + queryUrl;

    const session_token = sessionStorage.token
    const local_token = localStorage.token

    if (session_token ?? local_token) {
      const decoded = jwt.decode(session_token ?? local_token)
      if (decoded.exp < Date.now() / 1000 && uri !== '/auth/refresh') {
        const token = await Api.main.refreshToken()
        if (session_token) {
          sessionStorage.setItem('token', token)
        } else {
          localStorage.setItem('token', token)
        }
      }
    }

    const isFormData = data instanceof FormData;

    const res = await fetch(fullUrl, {
      method: type,
      headers: {
        Authorization: sessionStorage.token ?? localStorage.token,
        'Accept-Language': language,
        ...data instanceof Blob || isFormData ? {} : {'Content-Type': 'application/json' }
      },
      body: data instanceof Blob || isFormData ? data : data ? JSON.stringify(data) : null,
      credentials: 'include',
    })

    if (res.status === 401 && (session_token || local_token)) {
      Api.main.logOff();
      localStorage.removeItem('token');
      sessionStorage.removeItem('token');
      window.location.replace('/')
    }

    const contentType = res.headers.get('Content-Type')
    const isJson = contentType?.includes('application/json')

    const content = isJson ? await res.json() : res.text();

    if (!res.ok) {
      throw {
        status: res.status,
        message: isJson ? content.message : content || 'something went wrong',
        content,
      };
    }

    return content
  }
}

export default Base;
