import React, { memo } from "react";

import { Button } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { useTranslation } from 'localization/translation';

const Save = ({ isDisable }) => {
  const translation = useTranslation();
  return (
    <Button
      color="secondary"
      type="submit"
      variant="contained"
      className="d-flex my-3 mx-auto"
      disabled={isDisable}
    >
      <span className="btn-wrapper--label">{translation.save}</span>
      <SaveIcon />
    </Button>
  );
};

export default memo(Save);
