import React, { useCallback } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";

import { useText, usePostData } from "../../../hooks";
import useStore from "../../../context";

import * as Api from "../../../service";
import { Save } from "../../../components";
import { useTranslation } from "localization/translation";
import { REQUIRED_ERROR_MESSAGE } from "Constants";

const initForm = {
  id: "",
  message: "",
  branch_count: "",
};

const Form = () => {
  const translation = useTranslation();
  const postCallback = usePostData(Api.requestBranches.create);
  const { setErrorMsg } = useStore();

  const [text, setText, , input, error, setError] = useText(initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      setError({});

      const errors = {
        branch_count: !text.branch_count,
      };

      if (Object.values(errors).some((hasError) => hasError)) {
        setError(errors);
        return;
      }

      postCallback(text);
    },
    [postCallback, setErrorMsg, text]
  );

  const validateField = (field, value) => {
    let isValid = true;

    switch (field) {
      case "branch_count":
        isValid = !!value;
        break;
      default:
        isValid = true;
    }

    setError((prevErrors) => ({
      ...prevErrors,
      [field]: !isValid,
    }));
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setText((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            {...input("branch_count")}
            type="number"
            label="Branch count*"
            error={!!error?.branch_count}
            helperText={error?.branch_count && REQUIRED_ERROR_MESSAGE}
            onChange={handleTextChange}
          />
          <TextField
            {...input("message")}
            multiline
            label={translation.message}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
