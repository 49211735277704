import React, { useState, useEffect } from 'react';
import './style.scss';
import * as Api from '../../service';
import withHeaderAndFooter from 'components/with-header-and-footer-hoc';
import { useParams } from 'react-router';
import { ReactComponent as Loading } from 'assets/icons/loading.svg';
import { useTranslation } from 'localization/translation';

const EmailVerify = () => {
  const { token } = useParams();
  const translation = useTranslation();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

    useEffect(() => {
        Api.sign.emailVerify(token)
            .then((response) => {
                if (response.success) {
                    setSuccess(true);
                } else {
                    setSuccess(false);
                }
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    if (loading) {
        return (
            <section className="sign-section">
                <div className='load_icon'>
                    <Loading />
                </div>
            </section>
        )
    }

    if (error) {
        return (
            <section className="sign-section">
                < div className="main-container">
                    <h2>Email isn't verified, something went wrong!</h2>
                </div>
            </section >
        );
    }

    return (
        <section className="sign-section">
            < div className="main-container">
              {success ? (
                <h2>{translation.thank_you_email_is_verified}</h2>
              ) : (
                <h2>{translation.token_is_expired}</h2>
              )}
            </div>
        </section >
    );
};

export default withHeaderAndFooter(EmailVerify);
