import React from "react"
import * as Api from "../../service";
import { useGetData } from "../../hooks"
import useStore from "../../context"
import styles from "../styles.module.scss"
import withHeaderAndFooter from 'components/with-header-and-footer-hoc';

const Terms = () => {
    const [data] = useGetData(Api.terms.getAll)
    const { language } = useStore()
    return (
        <div className={styles.main}>
            {data && (
            <div className={styles.content}>
                <h1>
                    {data[`title_${language}`] ?? ""}
                </h1>
                <p>
                    {data[`text_${language}`] ?? ""}
                </p>
            </div>)}
        </div>
    )
}

export default withHeaderAndFooter(Terms)
