/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";

import { IikoHeader, IikoTables, UnresolvedModal } from "components";
import { useTranslation } from "localization/translation";
import { useGetData } from "hooks";
import * as Api from "service";
import {
  DELAY,
  FIRST_PAGE,
  IIKO_HOST_KEY,
  IIKO_STATUSES,
  LIMIT,
  PAGE,
  USER_ROLE,
  SEARCH,
} from "Constants";
import useStore from "context";

import { ReactComponent as Loading } from "assets/icons/loading.svg";
import { LAST_ELEMENT_KEY } from "Constants";
import { IIKO_LOGIN } from "Constants";

export const IikoIntegration = ({ type }) => {
  const [loading, setLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [unresolvedGoodsId, setUnresolvedGoodsId] = useState("");
  const [unresolvedByUnitGoodsId, setUnresolvedByUnitGoodsId] = useState("");
  const [search, setSearch] = useState("");

  const queryUrl = new URLSearchParams(useLocation().search);
  const history = useHistory();

  const [query, setQuery] = useState({
    status: "",
    search: queryUrl.get(SEARCH) ?? "",
    type,
    page: queryUrl.get(PAGE) ?? FIRST_PAGE,
    limit: LIMIT,
  });

  const { settings, user } = useStore();

  const translation = useTranslation();

  const [data, , refresh] = useGetData(Api.iikoProdcuts.getAll, query);

  const iikoProducts = data?.data?.items;
  const showTable = !!iikoProducts?.length;
  const searchInput = document.getElementById("iiko-products-search-input");

  let typingTimer;

  const handleFilterProducts = (status) => {
    queryUrl.set(PAGE, FIRST_PAGE);
    history.push(`?${queryUrl.toString()}`);

    setQuery((prev) => ({
      ...prev,
      status,
      page: FIRST_PAGE,
    }));
  };

  const handleSync = () => {
    setLoading(true);

    Api.main
      .iikoSync(type)
      .then((res) => {
        if (res?.success) {
          refresh();
          if (
            !!data?.data?.unresolvedCount ||
            !!data?.data?.unitUnresolvedCount
          )
            setShowModal(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const changeSearchQuery = useCallback((event) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      setQuery((prev) => ({
        ...prev,
        search: event.target.value,
        page: FIRST_PAGE,
      }));
      queryUrl.set(PAGE, FIRST_PAGE);
      queryUrl.set(SEARCH, event.target.value ?? "");
      history.push(`?${queryUrl.toString()}`);
    }, DELAY);
  }, []);

  const handleSearch = (e) => {
    if (e.target.value !== search) {
      setSearch(e.target.value);

      if (queryUrl.get(PAGE) !== FIRST_PAGE) {
        queryUrl.set(PAGE, FIRST_PAGE);
        history.push(`?${queryUrl.toString()}`);
      }

      if (e.target.value === "") {
        queryUrl.set(SEARCH, e.target.value ?? "");
        history.push(`?${queryUrl.toString()}`);
      }

      searchInput.addEventListener("input", changeSearchQuery);
    }
  };

  useEffect(() => {
    setUnresolvedGoodsId("");
    setUnresolvedByUnitGoodsId("");

    data?.data?.items?.forEach((elem) => {
      if (elem.status === IIKO_STATUSES.resolveIssue) {
        setUnresolvedGoodsId((prev) =>
          prev.concat(`${prev.length ? "," : ""}${elem.id}`)
        );
      } else if (elem.status === IIKO_STATUSES.unitResolveIssue) {
        setUnresolvedByUnitGoodsId((prev) =>
          prev.concat(`${prev.length ? "," : ""}${elem.id}`)
        );
      }
    });
  }, [data?.data?.items]);

  useEffect(() => {
    setQuery((prev) => ({
      ...prev,
      page: queryUrl.get(PAGE) || FIRST_PAGE,
      search: queryUrl.get(SEARCH) || "",
    }));
    setSearch(queryUrl.get(SEARCH) ?? "");
  }, [queryUrl.get(PAGE), queryUrl.get(SEARCH)]);

  useEffect(() => {
    if (settings?.length && user?.role !== USER_ROLE.manager) {
      setLoading(true);

      const data = {
        iiko_host: settings.find((elem) => elem?.key === IIKO_HOST_KEY)?.value,
        iiko_login: settings.find((elem) => elem?.key === IIKO_LOGIN)?.value,
        iiko_password: settings.find((elem) => elem?.key === LAST_ELEMENT_KEY)
          ?.value,
      };

      Api.main
        .checkIikoConnection(data)
        .then((res) => {
          if (res.success) {
            setIsLogin(true);
          }
        })
        .catch(() => setIsLogin(false))
        .finally(() => setLoading(false));
    } else if (user?.role === USER_ROLE.manager) {
      setIsLogin(true);
    }
  }, [settings]);

  return (
    <div className="iiko_container">
      <UnresolvedModal
        open={isLogin && showModal}
        setOpen={setShowModal}
        unresolved={data?.data?.unresolvedCount}
        unresolvedByUnit={data?.data?.unitUnresolvedCount}
        unresolvedGoodsId={unresolvedGoodsId}
        unresolvedByUnitGoodsId={unresolvedByUnitGoodsId}
      />
      {isLogin ? (
        !showTable && !query.search && !query.status ? (
          <div className="sync_products">
            {loading ? (
              <div className="load_icon">
                <Loading />
              </div>
            ) : (
              <>
                <IikoHeader handleSync={handleSync} />
                <p className="sync_text">{translation.sync_message}</p>
              </>
            )}
          </div>
        ) : (
          <>
            <IikoHeader
              isUnresolved
              showSearch
              showFilterBar
              handleSearch={handleSearch}
              search={search}
              handleSync={handleSync}
              data={data?.data}
              total={data?.total}
              handleFilterProducts={handleFilterProducts}
            />
            <IikoTables
              data={iikoProducts}
              total={data?.total}
              limit={query.limit}
            />
          </>
        )
      ) : (
        <div className="sync_products">
          {loading ? (
            <div className="load_icon">
              <Loading />
            </div>
          ) : (
            <>
              <IikoHeader handleSync={handleSync} disableSync={true} />
              <div className="sync_warning">
                <span className="sync_text">
                  {translation.credential_warning}
                </span>{" "}
                <Link
                  to="/dashboard/settings/integration"
                  className="setting_link"
                >
                  {translation.credentials_settings}
                </Link>{" "}
                <span className="sync_text">{translation.continue_sync}</span>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
