import React, { useCallback } from "react";
import { Card, Grid, Container, TextField } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";

import Autocomplete from "@material-ui/lab/Autocomplete";

const MultiSelect = ({
  field,
  label,
  data,
  value,
  onChange,
  required,
  error,
}) => {
  const filterChange = useCallback(
    (e, value) => {
      onChange(value.map((id) => (typeof id === "object" ? id.id : id)));
    },
    [onChange]
  );

  return (
    <Card className="shadow-xxl my-3 py-3">
      <Grid>
        <Container>
          <Autocomplete
            multiple
            filterSelectedOptions
            getOptionSelected={(a, b) => a.id === b}
            options={data}
            value={value}
            getOptionLabel={(option) => option[field]}
            defaultValue={[]}
            onChange={filterChange}
            renderTags={(value, getTagProps) =>
              value.map((id, index) => (
                <Chip
                  key={id}
                  label={
                    data.find((e) => e.id === id) &&
                    data.find((e) => e.id === id)[field]
                  }
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label={label}
                variant="outlined"
                placeholder={label}
                fullWidth
                required={required && value?.length === 0}
                error={error} // Display error state
                helperText={error && "Please select at least one option"} // Display error message
              />
            )}
          />
        </Container>
      </Grid>
    </Card>
  );
};

export default MultiSelect;
