import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Menu, MenuItem, Fade } from "@material-ui/core";
import {
  ACTIVE_STATUS,
  ACTIVE_STATUS_VALUE,
  DELETE,
  EDIT,
  INACTIVE_STATUS,
  INACTIVE_STATUS_VALUE,
  ACTION,
} from "Constants";
import StyledButton from "./styledButtons";
import { useTranslation } from "localization/translation";

export function ActionsDropdown({
  status,
  editUrl,
  id,
  handleDelete,
  setActionType,
  setBranchId,
  setIsActiveBranch,
  setIsOpenAction,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const translation = useTranslation();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeStatus = () => {
    setBranchId(id);
    setActionType(!!status ? INACTIVE_STATUS : ACTIVE_STATUS);
    setIsActiveBranch(!!status ? INACTIVE_STATUS_VALUE : ACTIVE_STATUS_VALUE);
    setIsOpenAction(true);
    handleClose();
  };

  const deleteHandler = () => {
    handleDelete(id);
    handleClose();
  };

  return (
    <div>
      <Button
        id="fade-button"
        aria-controls={open ? "fade-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="contained"
        color="secondary"
      >
        {translation.action_buttons}
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {!!status ? (
          <MenuItem>
            <StyledButton
              name={translation.deactivate}
              className={INACTIVE_STATUS}
              onClick={handleChangeStatus}
            />
          </MenuItem>
        ) : (
          <MenuItem>
            <StyledButton
              name={translation.activate}
              className={ACTIVE_STATUS}
              onClick={handleChangeStatus}
            />
          </MenuItem>
        )}
        <MenuItem>
          <Link to={editUrl} className="edit_button_container">
            <StyledButton
              name={translation.edit}
              className={EDIT}
              onClick={handleClose}
            />
          </Link>
        </MenuItem>
        <MenuItem>
          <StyledButton
            name={translation.delete}
            className={DELETE}
            onClick={deleteHandler}
          />
        </MenuItem>
      </Menu>
    </div>
  );
}
