import { KILOGRAM_IN_GRAMS } from "Constants";

export const calculate = {
  fullWeight: (empty, volume, density, isGram, isMilliliter) => {
    if(isGram) {
      const volumeValue = isMilliliter ? volume : (volume * KILOGRAM_IN_GRAMS);
      return (density * volumeValue + +empty).toString();
    } else {
      const volumeValue = isMilliliter ? volume/KILOGRAM_IN_GRAMS : volume;
      return (density * volumeValue + +empty).toString();
    }
  },
  emptyWeight: (full, volume, density, isGram, isMilliliter) => {
    if(isGram) {
      const volumeValue = isMilliliter ? volume : (volume * KILOGRAM_IN_GRAMS);
      return (full - density * volumeValue).toString();
    } else {
      const volumeValue = isMilliliter ? volume/KILOGRAM_IN_GRAMS : volume;
      return (full - density * volumeValue).toString();
    }
  },
  isEqual: (full, empty, volume, density, isGram, isMilliliter) => {
    const fillValue = isGram ? full : (full * KILOGRAM_IN_GRAMS);
    const emptyValue = isGram ? empty : (empty * KILOGRAM_IN_GRAMS);
    const volumeValue = isMilliliter ? volume : (volume * KILOGRAM_IN_GRAMS);
    if (fillValue - emptyValue === volumeValue * density) {
      return true;
    }
    return false;
  },
};
