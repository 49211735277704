import React, { useCallback, useState } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";
import Gallery from "../../../components/gallery";

import {
  useText,
  useGetData,
  usePutData,
  useGetList,
  usePostData,
} from "../../../hooks";
import useStore from "../../../context";

import * as Api from "../../../service";
import { Lang, Save, Select } from "../../../components";
import { useParams } from "react-router-dom";
import { useTranslation } from "localization/translation";

const initForm = {
  name_en: "",
  name_hy: "",
  name_ru: "",
  goods_type_id: "",
  weight_full: "",
  weight_empty: "",
  density: "",
  barcode: "",
  litrage: "",
  image: "",
};
const query = { limit: 0 };
const Form = () => {
  const { id } = useParams();
  const translation = useTranslation();
  const [types] = useGetList(Api.goodsType.getAll, query);
  const [data, , refresh] = useGetData(id && Api.goods.getById, id);
  const postCallback = usePostData(Api.goods.create, refresh);
  const updateCallback = usePutData(Api.goods.update, refresh);
  const { setErrorMsg, lang } = useStore();
  const [newFiles, setNewFiles] = useState(null);

  const [text, setText, , input] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      if (["hy", "ru", "en"].some((el) => !text[`name_${el}`]))
        return setErrorMsg("all names required");

      const link = newFiles
        ? await Api.main.uploadFile(newFiles[0], "client_goods")
        : text.image;

      text.weight_full = parseFloat(text.weight_full);
      text.weight_empty = parseFloat(text.weight_empty);
      text.density = parseFloat(text.density);
      text.litrage = parseFloat(text.litrage);

      const data = {
        ...text,
        image: link,
      };

      id ? updateCallback(data) : postCallback(data);
    },
    [id, postCallback, setErrorMsg, text, updateCallback, newFiles]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <Gallery
            single
            label="Image"
            setNewFile={setNewFiles}
            value={text.image ? text.image : null}
            setImages={(img) => setText((st) => ({ ...st, image: img }))}
            onChange={() => setText((st) => ({ ...st, image: "" }))}
          />
          <TextField {...input(`name_${lang}`)} />
          <TextField {...input(`weight_full`)} type="number" />
          <TextField {...input(`weight_empty`)} type="number" />
          <TextField {...input(`density`)} type="number" />
          <TextField {...input(`litrage`)} type="number" />
          <TextField {...input(`barcode`)} />
          {types && (
            <Select
              onChange={(val) =>
                setText((st) => ({ ...st, goods_type_id: val }))
              }
              value={text.goods_type_id}
              field={`name_${lang}`}
              label={translation.good_type}
              noCard
              options={types}
            />
          )}

          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
