import React, { useCallback, useState } from "react"
import Input from "../input"
import { useTextFront } from 'hooks';
import styles from "./styles.module.scss"
import * as Api from "../../../../service";
import { useTranslation } from 'localization/translation';
import useStore from "context";
import { CHOOSE_US_SUCCESS_MSG } from "Constants";

const initForm = {
  name: '',
  email: '',
  phone_number: '',
  text: '',

};

const Form = () => {
  const [text, setText, , input] = useTextFront(initForm);
  const [error, setError] = useState();
  const translation = useTranslation();
  const { setSuccessMsg } = useStore();

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    try {
      setError('');
      await Api.main.requestCallback(text);
      setSuccessMsg(CHOOSE_US_SUCCESS_MSG)
      setText(initForm);
    } catch (err) {

      setError(err.message);
    }
  }, [text]);

  return (
        <div className={styles.form}>
          <form onSubmit={(e) => onSubmit(e)}>
            <Input {...input.name} required type="text" placeholder={translation.owner_operator_company_name} />
            <Input {...input.email} required type="email" placeholder={translation.e_mail} />
            <Input {...input.phone_number} pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" required type="tel"  placeholder={translation.phone_number}/>
            <Input {...input.text} required type="text" placeholder={translation.comment} />
            <span className={styles.error}>{error}</span>
            <button type="submit" className={styles.button}>
              {translation.request_a_callback}
            </button>
          </form>
        </div>
    )
}

export default Form
