import React, { useState } from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions
} from '@material-ui/core';

import { useText } from '../hooks';
import useStore from '../context';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import update from 'immutability-helper';

import { Lang, DragCard } from './';

const List = ({
  renderLabel,
  renderForm,
  form,
  value,
  onChange,
  label,
  langTab = true
}) => {
  const { setRemove } = useStore();
  const [newItem, setNewItem, textChange, input] = useText(null);
  const [edit, setEdit] = useState(null);

  const close = () => {
    setNewItem(null);
    setEdit(null);
  };

  const move = (dragIndex, hoverIndex) => {
    const newItems = update(value, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, value[dragIndex]]
      ]
    });
    onChange(newItems);
  };

  const save = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (edit !== null) {
      value.splice(edit, 1, newItem);
    } else {
      value.push(newItem);
    }
    onChange(value);
    close();
  };

  return (
    <div>
      <IconButton
        disabled={newItem}
        onClick={() =>
          setNewItem(typeof form === 'object' ? { ...form } : form)
        }>
        <AddCircleIcon color="primary" fontSize="large" />
      </IconButton>
      {label && <span>{label}</span>}
      <div>
        {value?.map((el, i) => (
          <DragCard
            key={el._id ?? i}
            index={i}
            className="d-flex"
            moveCard={move}
            node={
              <div fullWidth className="d-flex w-100 px-5 align-items-center">
                {renderLabel(el, i)}
                <IconButton disabled={newItem} className="mx-3">
                  <EditIcon
                    onClick={() => {
                      setNewItem(el);
                      setEdit(i);
                    }}
                    color="primary"
                    fontSize="large"
                  />
                </IconButton>
                <IconButton
                  disabled={newItem}
                  onClick={() =>
                    setRemove(() => async () =>
                      onChange(value.filter((el, index) => i !== index))
                    )
                  }
                  className="mx-3">
                  <ClearIcon color="error" fontSize="large" />
                </IconButton>
              </div>
            }
          />
        ))}
      </div>

      <Dialog
        classes={{
          paper: `modal-content rounded-lg`
        }}
        open={newItem !== null}
        onClose={close}
        aria-labelledby="form-dialog-title">
        <form onSubmit={save}>
          <DialogTitle>{edit ? 'Edit' : 'Add'}</DialogTitle>
          <DialogContent style={{ minWidth: 400 }} className="p-4">
            {newItem !== null && (
              <DialogContentText className="mb-0">
                {langTab && <Lang />}
                {renderForm(newItem, setNewItem, textChange, input)}
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions className="p-4">
            <Button
              onClick={close}
              variant="contained"
              type="button"
              className="m-2">
              Cancel
            </Button>
            <Button
              color="secondary"
              type="submit"
              variant="contained"
              className="d-flex my-3 mx-auto">
              Done
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default List;
