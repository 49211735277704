import Resource from "./resource";
export const imgUrl = process.env.REACT_APP_imagesBaseUrl;

export { default as main } from "./main";
export { default as sign } from "./main-front";

export const goods = new Resource("/v2/client/goods");
export const goodsManager = new Resource("/manager/goods");
export const goodsType = new Resource("/client/goods-type");
export const goodsTypeManager = new Resource("/manager/goods-type");
export const branches = new Resource("/client/branches");
export const requestBranches = new Resource("/client/request-branches");
export const managerInvitations = new Resource("/client/manager-invitations");
export const managers = new Resource("/client/managers");
export const barmens = new Resource("/client/barmens");
export const inventories = new Resource("/v2/client/dashboard/inventories");
export const privacy = new Resource("/web/privacy-policy");
export const terms = new Resource("/web/terms-and-conditions");
export const goodsTypeOrder = new Resource("/client/goods-type/order");
export const settings = new Resource("/client/settings");
export const iikoProdcuts = new Resource("/v2/integration/iiko/products");
export const iikoUnresolvedProducts = new Resource(
  "/v2/integration/iiko/unresolved-items"
);
export const clientUnresolvedProducts = new Resource(
  "/v2/integration/iiko/unresolved-client-items"
);
export const mergeProducts = new Resource("/v2/integration/iiko/resolve");
export const getIikoInventories = new Resource(
  `/v2/integration/iiko/inventory-prepare`
);
export const getIikoStatistics = new Resource(
  "/v2/integration/iiko/status-counts"
);
export const getStores = new Resource("/v2/integration/iiko/stores");
export const sendIikoInventory = new Resource(
  "/v2/integration/iiko/inventory-sync"
);
export const getInventoryPrepare = new Resource(
  "/v2/integration/iiko/inventory-prepare"
);
export const support = new Resource("/v2/messenger");
