import React, { useState, useEffect } from "react"
import styles from "./styles.module.scss"
import ReadyToImprove from "./components/ready-to-improve-operations"
import TitleSubtitle from "components/titlie-subtitle"
import QuantitySlider from "./components/quantity-slider"
import useStore from "../../context";
import * as Api from "../../service";

const ManageInventory = () => {
  const { language } = useStore();
  const [itemSection, setItemSection] = useState({})
  useEffect(() => {
    Api.main.getItemsSection()
      .then(({ data }) => {
        setItemSection(data);
      })
  },[])
    return (
        <div className={styles.management}>
            <ReadyToImprove />
            <TitleSubtitle
                subtitle={itemSection?.[`title_${language}`]}
                text={itemSection?.[`description_${language}`]}
                subtitleClassName={styles.subtitle}
                textClassName={styles.text}
            />
            <QuantitySlider
              data={itemSection}
              lang={language}
            />
        </div>
    )
}

export default ManageInventory
