import React from "react";
import LoginWrapperContainer from "../../layout-components/LoginWrapperContainer";
import { ForgetPassword } from "components";

const NewForgetPassword = () => {
  return (
    <LoginWrapperContainer>
      <ForgetPassword />
    </LoginWrapperContainer>
  )
};

export default NewForgetPassword;
