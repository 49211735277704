import React, { useState } from "react";
import * as Api from "service";
import { useGetList } from "hooks";
import ChatIcon from "../../assets/icons/chat.png";

const baseUrl = process.env.REACT_APP_imagesBaseUrl;
const Support = () => {
  const [items] = useGetList(Api.support.getAll);
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className="support">
      {isOpen && (
        <div className="support-body">
          {items?.map((item, i) => (
            <a
              href={item.link}
              className={`support-item ${isOpen ? "animated" : ""}`}
              key={item.id}
              style={{ animationDelay: `${i * 0.2 + 0.5}s` }}
            >
              <span>
                <img
                  className="support-icon"
                  src={`${baseUrl}${item.icon}`}
                  alt="Icon"
                />
              </span>
            </a>
          ))}
        </div>
      )}
      {!!items?.length && (
        <div onClick={toggleOpen} className="support-button">
          <img alt="Chat" src={ChatIcon} />
          <span className="red-point" />
        </div>
      )}
    </div>
  );
};

export default Support;
