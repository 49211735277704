import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { Collapse } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from 'reducers/ThemeOptions';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import Menu from 'menu.js'
import useStore from 'context';
import { useTranslation } from 'localization/translation';
import {
  DEFAULT_UNRESOLVED_PRODUCTS_COUNT,
  INTEGRATION,
  MANAGER_SIDEBAR,
  SIDEBAR_MENU,
  USER_ROLE
} from 'Constants';
import { useGetList } from 'hooks';
import * as Api from "service";

const SidebarMenu = (props) => {
  const { setSidebarToggleMobile } = props;
  const [menuOpen, setMenuOpen] = useState(null);
  const { user } = useStore();
  const translation = useTranslation();

  const [statistics] = useGetList(Api.getIikoStatistics.getAll);

  const unresolvedElements = useMemo(() => {
    return statistics ?
      statistics?.unitUnresolvedCount + statistics?.unresolvedCount :
      DEFAULT_UNRESOLVED_PRODUCTS_COUNT
    }, [statistics])

  const handleCollapseClick = (e, index) => {
    e.preventDefault();
    setMenuOpen(st => st === index ? null : index)
  }

  return (
    <>
      <PerfectScrollbar>
        <div className="sidebar-navigation">
          <div className="sidebar-header">
            <span>{translation.navigation_menu}</span>
          </div>
          <ul>
            {user?.role === USER_ROLE.customer && Object.keys(Menu).map((el, index) =>
              <li key={index}>
                {Array.isArray(Menu[el]) ?
                  <>
                    <a
                      href="#/"
                      onClick={(e) => { e.preventDefault(); setMenuOpen(st => st === index ? null : index) }}
                      className={clsx({ active: menuOpen === index })}>
                      <span className="sidebar-icon">
                        {SIDEBAR_MENU[el] ?
                          <img src={SIDEBAR_MENU[el]} alt={el} /> :
                          <VerifiedUserTwoToneIcon />
                        }
                      </span>
                      <span style={{
                        textTransform: 'capitalize'
                      }} className="sidebar-item-label">
                        {translation[el]}
                        {el === INTEGRATION && !!unresolvedElements && (
                          <span className="unresolved_count">{unresolvedElements}</span>
                        )}
                      </span>
                      <span className="sidebar-icon-indicator">
                        <ChevronRightTwoToneIcon />
                      </span>
                    </a>
                    <Collapse in={menuOpen === index}>
                      <ul>
                        {Menu[el].map((e,i) =>
                          <li key={i}>
                            <NavLink
                              exact
                              onClick={() => setSidebarToggleMobile(false)}
                              to={e.link}>
                              {translation[e.name]}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </> : <NavLink
                    to={Menu[el]}
                    onClick={() => { setSidebarToggleMobile(false); setMenuOpen(null) }}>
                    <span className="sidebar-icon">
                      {SIDEBAR_MENU[el] ?
                        <img src={SIDEBAR_MENU[el]} alt={el} /> :
                        <VerifiedUserTwoToneIcon />
                      }
                    </span>
                    <span className="sidebar-item-label">
                      {translation[el]}
                    </span>
                  </NavLink>
                }
              </li>
            )}

            {user?.role === USER_ROLE.manager && <li>
                {MANAGER_SIDEBAR.map((element, index) => element?.subCategories?.length ? (
                  <>
                    <a
                      href="#/"
                      onClick={(e) => handleCollapseClick(e, index)}
                      className={clsx({ active: menuOpen === index })}>
                      <span className="sidebar-icon">
                          <img src={element.image} alt={element.alt} />
                      </span>
                      <span style={{
                        textTransform: 'capitalize'
                      }} className="sidebar-item-label">
                        {translation[element.name]}
                        {element.name === INTEGRATION && !!unresolvedElements && (
                          <span className="unresolved_count">{unresolvedElements}</span>
                        )}
                      </span>
                      <span className="sidebar-icon-indicator">
                        <ChevronRightTwoToneIcon />
                      </span>
                    </a>
                    <Collapse in={menuOpen === index}>
                      <ul>
                        {element.subCategories.map((e,i) =>
                          <li key={i}>
                            <NavLink
                              exact
                              onClick={() => setSidebarToggleMobile(false)}
                              to={e.link}>
                              {translation[e.name]}
                            </NavLink>
                          </li>
                        )}
                      </ul>
                    </Collapse>
                  </>
                ) :(
                  <NavLink
                    to={element.href}
                    onClick={() => { setSidebarToggleMobile(false); setMenuOpen(null) }}
                  >
                    <span className="sidebar-icon">
                      <img src={element.image} alt={element.alt} />
                    </span>
                    <span className="sidebar-item-label">
                      {translation[element.name]}
                    </span>
                  </NavLink>
                ))}
            </li>}
          </ul>
        </div>
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = (state) => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu);
