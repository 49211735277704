import React from "react";
import "./styles.scss";

const Input = ({
  type = "text",
  placeholder,
  multiple,
  autoComplete,
  name,
  value,
  onChange,
  required = false,
  pattern,
  title,
  accept,
  error,
  errorMessage,
}) => (
  <div className="input_field">
    <input
      multiple={multiple}
      required={required}
      className={`input ${error ? "error_input" : ""}`}
      name={name}
      value={value}
      onChange={onChange}
      type={type}
      placeholder={placeholder}
      autoComplete={autoComplete}
      pattern={pattern}
      title={title}
      accept={accept}
      error={!!error}
    />
    <span className="error_message">{errorMessage}</span>
  </div>
);
export default Input;
