import React, { useImperativeHandle, useRef } from "react";
import { DragSource, DropTarget } from "react-dnd";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cursor: {
    cursor: "move",
  },
}));

const CardDrag = React.forwardRef(
  (
    {
      node,
      isDragging,
      connectDragSource,
      connectDropTarget,
      div,
      className = "",
      activateDrag,
      hideElement,
    },
    ref
  ) => {
    const classes = useStyles();

    const elementRef = useRef(null);
    connectDragSource(elementRef);
    connectDropTarget(elementRef);
    const opacity = isDragging ? 0 : 1;

    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }));
    return div ? (
      <div
        className={`${className} ${classes.cursor}`}
        style={{ opacity }}
        ref={elementRef}
      >
        {node}
      </div>
    ) : (
      <>
        <tr
          className={`px-2 py-2 m-2 my-3 font-weight-bold ${className} ${
            activateDrag && !hideElement && "hover_effect"
          }`}
          ref={elementRef}
          style={{ opacity: hideElement ? "0" : "1" }}
        >
          {node}
        </tr>
        <tr className="divider" />
      </>
    );
  }
);
export default DropTarget(
  "card",
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards

      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;

      props.updatePosition({ monitor: monitor?.internalMonitor });
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  })
)(
  DragSource(
    "card",
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index,
      }),
      canDrag: (props) => props.activateDrag,
      endDrag: (props) => props.dragEnd(),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    })
  )(CardDrag)
);
