import React, { useCallback, useEffect, useState } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useText, useGetData, usePutData, usePostData } from "../../../hooks";
import useStore from "../../../context";

import * as Api from "../../../service";
import { Save } from "../../../components";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "localization/translation";
import { isValidPhone } from "helpers/formValidation";

import { OPEN_STREET_MAP_URL } from "../constants";
import { PHONE_FIELD_NAME, REQUIRED_ERROR_MESSAGE } from "Constants";

const initForm = {
  name: "",
  phone: "",
  address: "",
  longitude: 0,
  latitude: 0,
};

const Form = () => {
  const translation = useTranslation();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [data, , refresh] = useGetData(id && Api.branches.getById, id);
  const postCallback = usePostData(Api.branches.create, refresh);
  const updateCallback = usePutData(Api.branches.update, refresh);
  const { setErrorMsg } = useStore();

  const [text, setText, textChange, input, error, setError] = useText(
    data || initForm
  );
  const phoneValidator = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

  useEffect(() => {
    if (data) setValue(data?.address);
  }, [data]);

  const validateField = (field, value) => {
    let isValid = true;
    let errorMessage = "";

    switch (field) {
      case "name":
        isValid = !!value;
        errorMessage = isValid ? "" : REQUIRED_ERROR_MESSAGE;
        break;
      case "phone":
        if (!value) {
          isValid = false;
          errorMessage = REQUIRED_ERROR_MESSAGE;
        } else if (!isValidPhone(value)) {
          isValid = false;
          errorMessage = translation.please_enter_valid_phone;
        }
        break;
      case "address":
        isValid = !!value;
        errorMessage = isValid ? "" : REQUIRED_ERROR_MESSAGE;
        break;
      default:
        isValid = true;
    }

    setError((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;

    const validPhoneNumber = /^\+?\d*$/;

    const isPhone = name === PHONE_FIELD_NAME;
    const isPhoneValid = isPhone && !validPhoneNumber.test(value);

    let phoneNumberValue = isPhone && value.replace(/[^\d]/g, "");

    if (isPhone && value.charAt(0) === "+") {
      phoneNumberValue = `+${phoneNumberValue}`;
    }

    setText((prev) => ({
      ...prev,
      [name]: isPhoneValid ? phoneNumberValue : value,
    }));
    validateField(name, isPhoneValid ? phoneNumberValue : value);
  };

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      setError({});

      const errors = {
        name: !text.name,
        phone:
          !text.phone || !isValidPhone(text.phone)
            ? translation.please_enter_valid_phone
            : false,
        address: !text.latitude,
      };

      if (Object.values(errors).some((hasError) => hasError)) {
        setError(errors);
        return;
      }

      id ? updateCallback(text) : postCallback(text);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  const searchPlace = (e) => {
    if (e) setValue(e?.target?.value ?? "");
  };

  const handleAddressSelection = (address) => {
    if (address) {
      setBranchInitialData(address);
      setError((prevErrors) => ({
        ...prevErrors,
        address: false,
      }));
    }
  };

  const setBranchInitialData = (value) => {
    setValue(value ? value?.display_name : "");
    const location = {
      latitude: value?.lat ?? 0,
      longitude: value?.lon ?? 0,
      address: value?.display_name,
    };
    setText((prev) => ({
      ...prev,
      ...location,
    }));
    validateField("address", value?.display_name || "");
  };

  useEffect(() => {
    const timeOutId = setTimeout(async () => {
      if (value) {
        const url = `${OPEN_STREET_MAP_URL}search?q=${value}&countrycodes=am,ae,ru&addressdetails=1&format=json`;
        const res = await axios.get(url);
        const optionsData = res.data.map((option) => ({
          label: option.display_name,
          value: option,
        }));
        setOptions(optionsData);
      }
    }, 700);

    return () => clearTimeout(timeOutId);
  }, [value]);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            {...input("name")}
            label={`${translation.name}*`}
            error={!!error?.name}
            helperText={error?.name && REQUIRED_ERROR_MESSAGE}
            onChange={handleTextChange}
          />

          <TextField
            {...input("phone")}
            label={`${translation.phone}*`}
            error={!!error?.phone}
            helperText={
              !!error?.phone
                ? typeof error?.phone === "string"
                  ? error.phone
                  : REQUIRED_ERROR_MESSAGE
                : null
            }
            onChange={handleTextChange}
          />

          <Autocomplete
            freeSolo
            options={options}
            onChange={(e, values) => handleAddressSelection(values?.value)}
            filterOptions={(x) => x}
            getOptionLabel={(option) => option.label}
            onInputChange={(e) => searchPlace(e)}
            inputValue={value}
            renderInput={(params) => (
              <TextField
                {...params}
                label={`${translation.address}*`}
                variant="outlined"
                placeholder={translation.fill_the_address}
                error={!!error?.address}
                helperText={error?.address && REQUIRED_ERROR_MESSAGE}
                fullWidth
              />
            )}
          />

          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
