import React from "react";

export const ChangeSettings = ({
  title,
  selected,
  handleChange,
  massName,
  microMassName,
  value,
  valueMill
}) => {
  return (
    <div className="change_measurement">
      <p className="measurement_title">{title}</p>
      <div className="select_option_container">
        <button
          className={`select_btn ${selected ? "selected" : ""}`}
          onClick={() => handleChange(valueMill)}
        >
          {microMassName}
        </button>
        <button
          className={`select_btn ${selected ? "" : "selected"}`}
          onClick={() => handleChange(value)}
        >
          {massName}
        </button>
      </div>
    </div>
  )
}