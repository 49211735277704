import React, { useEffect, useState } from "react"
import useStore from "../../context";
import * as Api from "../../service";
import styles from "./styles.module.scss"
import Adventages from "./adventages"

const WhyYouWillLoveBarControl = () => {
    const { language } = useStore();
    const [benefits, setBenefits] = useState({});
    useEffect(() => {
        Api.main.getBenefits()
          .then(({ data }) => {
              setBenefits(data);
          })
    },[])
    return (
        <div className={styles.main_section}>
            <div className={styles.content}>
                <h1 className={styles.title}>
                    {benefits?.[`title_${language}`]}
                </h1>
                <Adventages
                    title={benefits?.[`item1_title_${language}`]}
                    description={benefits?.[`item1_description_${language}`]}
                    icon={benefits?.item1_icon}
                />
                <Adventages
                    title={benefits?.[`item2_title_${language}`]}
                    description={benefits?.[`item2_description_${language}`]}
                    icon={benefits?.item2_icon}
                />
                <Adventages
                    title={benefits?.[`item3_title_${language}`]}
                    description={benefits?.[`item3_description_${language}`]}
                    icon={benefits?.item3_icon}
                />
            </div>
        </div>
    )
}

export default WhyYouWillLoveBarControl
