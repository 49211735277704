import React from "react";
import LoginWrapperContainer from "../../layout-components/LoginWrapperContainer";
import { SignUp } from "components";

const SignUpPage = () => {
  return (
    <LoginWrapperContainer>
      <SignUp />
    </LoginWrapperContainer>
  )
};

export default SignUpPage;
