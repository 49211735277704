import scroll from "react-scroll";

export const scrollToElement = (url, i, history) => {
  if (url) {
    history.push(`/`);
  }
  setTimeout(() => {
    const el = document?.getElementById(`${i}`);
    if (el) {
      const top = el.getBoundingClientRect().top + window.pageYOffset - 70;
      scroll.animateScroll.scrollTo(top, { duration: 500 });
    }
  }, 500);
};
