import React from "react"
import TitleSubtitle from "components/titlie-subtitle"
import styles from "./styles.module.scss"

const ToolComponent = ({ title, subTitle, description, image, isReverse = false, id }) => {
    return (
        <div id={id} className={`${styles.main}  ${isReverse ? styles.content_reverse : styles.content}`}>
            <TitleSubtitle
                title={title}
                subtitle={subTitle}
                text={description}
                subtitleClassName={styles.subtitle}
                textClassName={styles.text}
            />
            <img src={image} />
        </div>
    )
}

export default ToolComponent
