import React, {useEffect, useState} from "react"
import TitleSubtitle from "components/titlie-subtitle"
import Form from "./components/form"
import styles from "./styles.module.scss"
import useStore from "context";
import * as Api from "service";

const ParticularPrinciples = () => {
  const { language } = useStore();
  const [contactSection, setContactSection] = useState({});

  const getContact = () => {
    Api.main.getContactSection()
      .then(({ data }) => {
        setContactSection(data);
      })
  };

  useEffect(() => {
    getContact();
  },[]);
    return (
        <div id="contact_us" className={styles.particular}>
            <div className={styles.left_section}>
                <TitleSubtitle
                    title={contactSection?.[`secondary_title_${language}`]}
                    subtitle={contactSection?.[`title_${language}`]}
                    text={contactSection?.[`description_${language}`]}
                    subtitleClassName={styles.subtitle}
                    textClassName={styles.text}
                />
            </div>
            <div className={styles.right_section}>
                <Form />
            </div>
        </div>
    )
}

export default ParticularPrinciples
