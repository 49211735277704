import React from 'react';
import RightPlanItem from "./RightPlanItem";
import TitleSubtitle from "../titlie-subtitle"

import styles from './styles.module.scss'

const data = [
  {
    title: 'Base',
    amount: 'Free',
    content: [
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      }
    ]
  },
  {
    title: 'Base',
    amount: '50 $ / month',
    content: [
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      }
    ]
  },
  {
    title: 'Enterprise',
    amount: '200 $ / month',
    content: [
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      },
      {
        text: 'Lorem Ipsum is simply dummy text of the printing ',
      }
    ]
  }
];

const RightPlan = () => {
  return (
    <div className={styles.container}>
      <div className={styles.contentDescription}>
        <TitleSubtitle
          title="subscription"
          subtitle="The right plan for your bar"
          text="We have several powerful plans to showcase your bar and get discovered as a creative entrepreneurs. Everything you need."
          subtitleClassName={styles.subtitle}
          textClassName={styles.text}
        />
      </div>
      <div className={styles.rightItemWrapper}>
        {data.map((item) => (
            <RightPlanItem
              amount={item.amount}
              content={item.content}
              title={item.title}
            />
          ))
        }
      </div>
    </div>
  );
};

export default RightPlan;
