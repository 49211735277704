/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useStore from 'context';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Snack_bar = () => {
    const { successMsg, errorMsg, setSuccessMsg, setErrorMsg } = useStore()

    useEffect(() => {
        if (!!successMsg) {
            toast.success(successMsg)
            setSuccessMsg("");
        }
    }, [successMsg])

    useEffect(() => {
        if (!!errorMsg) {
            toast.error(errorMsg)
            setErrorMsg("");
        }
    }, [errorMsg])

    return <ToastContainer />;
};

export default Snack_bar;