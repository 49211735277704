import React from "react"
import styles from "./styles.module.scss"
import ProcessStep from "../process-step-component"

const Process = ({steps, lang}) => {
    return (
        <div className={styles.process}>
            <ProcessStep
                stepNumber="1"
                title={steps?.[`item1_title_${lang}`]}
                description={steps?.[`item1_description_${lang}`]}
            />
            <ProcessStep
              stepNumber="2"
              title={steps?.[`item2_title_${lang}`]}
              description={steps?.[`item2_description_${lang}`]}
            />
            <ProcessStep
              stepNumber="3"
              title={steps?.[`item3_title_${lang}`]}
              description={steps?.[`item3_description_${lang}`]}
            />
        </div>
    )
}

export default Process
