import React from "react";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useGetData } from "hooks";
import * as Api from "service";
import { inventoryDateFormat } from "helpers/inventoryDateFormat";
import { useTranslation } from "localization/translation";
import {
  EMPTY_WEIGHT,
  MILLILITERS_VALUE,
  MILLILITER,
  KILOGRAM_IN_GRAMS,
  GRAM,
  GRAMS_VALUE,
} from "Constants";
import useStore from "context";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: "50% !important",
    left: "50% !important",
    transform: "translate(-50%,-50%) !important",
  },
}));

const HistoryModal = ({ open, closeHandler }) => {
  const translation = useTranslation();
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [items] = useGetData(Api.inventories.getById, "changelog/" + open);

  const { volumeValue, weightValue } = useStore();

  const body = (
    <div className={`${classes.paper} modal-popup`}>
      <h2 id="simple-modal-title">{translation.history_of_scales}</h2>
      <div id="simple-modal-description">
        <table className="table">
          <thead>
            <th>{translation.barcode}</th>
            <th>{translation.full_bottle}</th>
            <th>{translation.count_of_bottles}</th>
            <th>{translation.left_over}</th>
            <th>{translation.updated_at}</th>
            <th>{translation.deleted_at}</th>
          </thead>
          <tbody>
            {items?.map((history) => {
              return (
                <tr>
                  <td>{history?.barcode}</td>
                  <td>
                    {history?.after?.data?.measurement_unit ===
                    MILLILITERS_VALUE
                      ? history?.after?.data?.is_full
                        ? translation.full
                        : translation.open
                      : "-"}
                  </td>
                  <td>
                    <p>{`${
                      history?.before?.data?.count ? translation.before : ""
                    } ${history?.before?.data?.count ?? "-"}`}</p>
                    <p>{`${
                      history?.after?.data?.count ? translation.after : ""
                    } ${
                      history?.after.deleted
                        ? EMPTY_WEIGHT
                        : history?.after?.data?.count ?? "-"
                    }`}</p>
                  </td>
                  <td>
                    <p>{`${translation.before} ${
                      history?.before?.data?.measurement_unit ===
                      MILLILITERS_VALUE
                        ? volumeValue === MILLILITER
                          ? Math.ceil(history?.before?.data?.millilitres)
                          : Math.ceil(history?.before?.data?.millilitres) /
                            KILOGRAM_IN_GRAMS
                        : history?.before?.data?.measurement_unit ===
                          GRAMS_VALUE
                        ? weightValue === GRAM
                          ? Math.ceil(history?.before?.data?.leftover)
                          : Math.ceil(history?.before?.data?.leftover) /
                            KILOGRAM_IN_GRAMS
                        : Math.ceil(history?.before?.data?.leftover)
                    }`}</p>
                    <p>{`${translation.after} ${
                      history?.after.deleted
                        ? EMPTY_WEIGHT
                        : history?.after?.data?.measurement_unit ===
                          MILLILITERS_VALUE
                        ? volumeValue === MILLILITER
                          ? Math.ceil(history?.after?.data?.millilitres)
                          : Math.ceil(history?.after?.data?.millilitres) /
                            KILOGRAM_IN_GRAMS
                        : history?.after?.data?.measurement_unit === GRAMS_VALUE
                        ? weightValue === GRAM
                          ? Math.ceil(history?.after?.data?.leftover)
                          : Math.ceil(history?.after?.data?.leftover) /
                            KILOGRAM_IN_GRAMS
                        : Math.ceil(history?.after?.data?.leftover)
                    }`}</p>
                  </td>
                  <td>
                    {history?.after?.data?.updated_at &&
                      inventoryDateFormat(history?.after?.data?.updated_at)}
                  </td>
                  <td>
                    {history?.after?.data?.deleted_at &&
                      inventoryDateFormat(history?.after?.data?.deleted_at)}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default HistoryModal;
