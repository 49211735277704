import React, { memo, useEffect } from "react";
import { useLocation } from "react-router";
import { List, ListItem } from "@material-ui/core";
import { FlagIcon } from "react-flag-kit";
import useStore from "../context";
import { CURRENT_TO_NEXT_LANG } from "Constants";
import * as Api from "service";

const Lang = ({ toggle }) => {
  const location = useLocation();
  const { lang, setLang, language, setLanguage, user } = useStore();

  const changeLanguage = () => {
    setLanguage((st) => CURRENT_TO_NEXT_LANG[st]);
    localStorage.setItem("language", CURRENT_TO_NEXT_LANG[language]);
    changeUserLanguage(CURRENT_TO_NEXT_LANG[language]);
  };

  const changeUserLanguage = async (lang) => {
    try {
      if (user?.id) {
        await Api.main.changeLanguage({
          lang,
          user_id: user.id,
        });
      }
    } catch (err) {}
  };

  useEffect(() => {
    setLang("en");
    changeUserLanguage(language);
  }, [location]);

  return toggle ? (
    <ListItem button disableRipple onClick={changeLanguage}>
      <FlagIcon
        code={language === "hy" ? "AM" : language === "ru" ? "RU" : "US"}
        size={44}
      />
      <div className="divider" />
    </ListItem>
  ) : (
    <List component="div" className="my-3 nav-tabs nav-tabs-primary d-flex">
      <ListItem
        button
        disableRipple
        selected={lang === "en"}
        onClick={() => setLang("en")}
      >
        <FlagIcon code="US" size={44} />
        <div className="divider" />
      </ListItem>
      <ListItem
        button
        disableRipple
        selected={lang === "hy"}
        onClick={() => setLang("hy")}
      >
        <FlagIcon code="AM" size={44} />
        <div className="divider" />
      </ListItem>
      <ListItem
        button
        disableRipple
        selected={lang === "ru"}
        onClick={() => setLang("ru")}
      >
        <FlagIcon code="RU" size={44} />
        <div className="divider" />
      </ListItem>
    </List>
  );
};

export default memo(Lang);
