import React, { useEffect, useState } from "react"
import styles from "./styles.module.scss"
import TitleSubtitle from "components/titlie-subtitle"
import Process from "./components/process"
import * as Api from "../../service";
import useStore from "../../context";
import { ReactComponent as ProcessImage } from "../../assets/images/processMob.svg";

const AboutTeamAndProcess = () => {
  const { language } = useStore();
  const [steps, setSteps] = useState({})
  useEffect(() => {
    Api.main.getSteps()
      .then(({ data }) => {
        setSteps(data);
      })
  },[])
    return (
        <div className={styles.main_content} id="how_it_work">
          <div className={styles.short_about}>
              <TitleSubtitle
                  title={steps?.[`secondary_title_${language}`]}
                  subtitle={steps?.[`title_${language}`]}
                  text={steps?.[`description_${language}`]}
                  subtitleClassName={styles.subtitle}
                  textClassName={styles.text}
              />
          </div>
          <div className={styles.process}>
            <ProcessImage />
            <Process steps={steps} lang={language} />
          </div>
        </div>
    )
}

export default AboutTeamAndProcess
