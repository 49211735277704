import React from "react";

import { Link } from 'react-router-dom';

import useStore from 'context';

import { Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

import Table from "components/tables/table";
import { StatusLabel } from "./label";
import { WrapperSeamless } from "layout-components";
import { useTranslation } from "localization/translation";
import { getMeasurementFullName } from "helpers/getMeasurementName";
import {
  DEFAULT_TOTAL,
  IIKO_STATUSES,
  ROUTES,
  STATUS_LABEL_CLASS,
  STATUS_LABEL_NAMES
} from "Constants";

export const IikoTables = ({ data, total, limit }) => {
  const { language, weightValue, volumeValue } = useStore();

  const translation = useTranslation();

  const rows = data?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item?.name}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {getMeasurementFullName(item?.compare_unit, language, weightValue, volumeValue)}
        </div>
      </td>
      <td className="text-left px-4">
        <div className={`font-size-sm`}>
          <StatusLabel
            customClass={STATUS_LABEL_CLASS[item.status]}
            name={translation[STATUS_LABEL_NAMES[item.status]]}
          />
        </div>
      </td>
      <td className="text-right">
        {item?.status !== IIKO_STATUSES.match  && (
            <Link to={{
              pathname: ROUTES.iiko_unresolved,
              state: {
                iiko: item?.id,
                goods_unit: item?.compare_unit,
                goods: item?.client_good_id,
                isEdit: true,
              }
            }}>
              <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
                <EditIcon color="primary" />
              </Button>
            </Link>
          )
        }
      </td>
    </>
  ));

  return (
    <div className="iiko_tables">
      <WrapperSeamless>
        <Table
          noDrag
          noCreateBtn
          title={translation.iiko_products}
          items={data}
          total={total || DEFAULT_TOTAL}
          fields={
            <>
              <th className="text-left px-4">
                <span className="mr-3">{translation.name}</span>
              </th>
              <th className="text-left px-4">
                <span className="mr-3">{translation.measurement_unit}</span>
              </th>
              <th className="text-left px-4">
                <span className="mr-3">{translation.status}</span>
              </th>
            </>
          }
          rows={rows}
          limit={limit}
        />
      </WrapperSeamless>
    </div>
  );
};