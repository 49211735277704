import React from "react";
import { Button, Dialog } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useStore from "../context";
import { useTranslation } from "localization/translation";

const Delte_confirm = () => {
  const {
    remove,
    setRemove,
    goodInInventoryRemove,
    setGoodInInventoryRemove,
  } = useStore();

  const translation = useTranslation();

  return (
    <Dialog
      open={!!remove}
      onClose={() => setRemove(null)}
      classes={{ paper: "shadow-lg rounded" }}
    >
      <div className="text-center p-5">
        <div className="avatar-icon-wrapper rounded-circle m-0">
          <div className="d-inline-flex justify-content-center p-0 rounded-circle btn-icon avatar-icon-wrapper bg-neutral-danger text-danger m-0 d-130">
            <FontAwesomeIcon
              icon={["fas", "times"]}
              className="d-flex align-self-center display-3"
            />
          </div>
        </div>
        <h4 className="font-weight-bold mt-4">
          {goodInInventoryRemove
            ? translation.are_you_sure_you_want_to_delete_good
            : translation.are_you_sure_you_want_to_delete}
        </h4>
        <p className="mb-0 font-size-lg text-muted">
          {translation.you_cannot_undo}
        </p>
        <div className="pt-4">
          <Button
            onClick={() => {
              setGoodInInventoryRemove(false);
              setRemove(null);
            }}
            className="btn-neutral-secondary btn-pill mx-1"
          >
            <span className="btn-wrapper--label">{translation.cancel}</span>
          </Button>
          <Button
            onClick={() => remove().then(() => setRemove(null))}
            className="btn-danger btn-pill mx-1"
          >
            <span className="btn-wrapper--label">{translation.delete}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default Delte_confirm;
