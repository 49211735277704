import React, { useCallback, useState } from 'react';
import { WrapperSeamless } from '../../../layout-components';
import Table from '../../../components/tables/table';
import { Button, FormControlLabel, Switch } from '@material-ui/core';
import { useGetList, usePutData, useText } from '../../../hooks';
import { Link } from 'react-router-dom';
import * as Api from '../../../service';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'localization/translation';

const initForm = {
  id: '',
  active: '',
};
export default function List() {
  const translation = useTranslation();
  const [query, setQuery] = useState({
    sort: 'created_at',
    order: 'desc',
  });

  const [waiting, setWaiting] = useState(false)

  const [items, total, refresh, setItems] = useGetList(
    Api.managers.getAll,
    query
  );
  const [text, setText, , input] = useText(items || initForm);
  const updateCallback = usePutData(Api.managers.updateList, refresh, false);

  const submitForm = useCallback(
    async (i) => {
      setWaiting(true)
      await updateCallback(text[i])
      try {
        setWaiting(false)
      } catch(error) {
        console.log(error)
      }
    },
    [text, updateCallback, setWaiting]
  );

  const toggleActive = (i, active) => {
    text[i].active = !active

    setText(st => ({...st}))
    submitForm(i)
  }

    const rows = items?.map((item, index) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.name}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.surname}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.phone}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.email}</div>
      </td>
      <td className="text-left px-4">
        <div style={{textTransform: 'capitalize'}} className="font-size-sm">{item.role}</div>
      </td>
      <td className="text-left px-4">
        <FormControlLabel
                style={{marginLeft: 0}}
                control={
                <Switch
                    checked={text[index] ? text[index].active : false}
                    onClick={() => toggleActive(index, text[index].active)}
                    className="switch-medium"
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />}
            />
      </td>
      <td className="text-right">
        <Link to={`/dashboard/managers/edit/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.first_name}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.last_name}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.phone}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.email}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.role}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.active}</span>
            </th>
          </>
        }
        noCreateBtn
        noDrag
        title={translation.items}
        items={items}
        setItems={setItems}
        total={total}
        rows={rows}
        createLink="dashboard/managers/edit"
      />
    </WrapperSeamless>
  );
}
