import {
  KILOGRAMS_VALUE,
  KILOGRAM_IN_GRAMS,
  MEASUREMENT_OPTIONS_TEMPORARY,
  MILLILITERS_VALUE,
  MEASUREMENT_KILOGRAM_OPTION,
  GRAMS_VALUE,
  PEACE_VALUE,
  KILOGRAM,
  MILLILITER,
} from "Constants";

const getNameDependsOfSettings = (data, language, weight, volume) => {
  switch (data?.id) {
    case MILLILITERS_VALUE:
      return volume === MILLILITER ?
        data?.[`value_${language}`] :
        data?.[`changed_value_${language}`];
    case GRAMS_VALUE:
      return weight === KILOGRAM ?
        data?.[`value_${language}`] :
        data?.[`changed_value_${language}`];
    case PEACE_VALUE:
      return data?.[`value_${language}`]
    default:
      return null;
  }
}

export const getMeasurementName = (id, language, weight, volume) => {
  const data = MEASUREMENT_OPTIONS_TEMPORARY.find((elem) => elem.id === id);
  const kilogram = MEASUREMENT_KILOGRAM_OPTION.id === id && MEASUREMENT_KILOGRAM_OPTION;

  const kiloName =
    weight === KILOGRAM ?
      kilogram?.[`value_${language}`] :
      kilogram?.[`changed_value_${language}`];
  const name = getNameDependsOfSettings(data, language, weight, volume);

  return name || kiloName || "";
}

export const getLeftOver = ({
  unit,
  totalLiters,
  leftOver = 0,
  language,
  weight,
  volume
}) => {
  switch (unit) {
    case MILLILITERS_VALUE:
      return volume === MILLILITER ?
        `${totalLiters.toFixed(2)} ${getMeasurementName(unit, language, weight, volume)}` :
        `${totalLiters/KILOGRAM_IN_GRAMS} ${getMeasurementName(unit, language, weight, volume)}`;
    case GRAMS_VALUE:
      return weight === KILOGRAM ?
        `${leftOver/KILOGRAM_IN_GRAMS} ${getMeasurementName(unit, language, weight, volume)}` :
        `${leftOver} ${getMeasurementName(unit, language, weight, volume)}`;
    case KILOGRAMS_VALUE:
      return weight === KILOGRAM ?
        `${leftOver} ${getMeasurementName(unit, language, weight, volume)}`:
        `${leftOver * KILOGRAM_IN_GRAMS} ${getMeasurementName(unit, language, weight, volume)}`;
    default:
      return `${leftOver} ${getMeasurementName(unit, language, weight, volume)}`
  }
}

export const getMeasurementFullName = (id, language, weight, volume) => {
  const unit = MEASUREMENT_OPTIONS_TEMPORARY.find((elem) => elem.id === id);
  switch (id) {
    case MILLILITERS_VALUE:
      return volume === MILLILITER ?
        unit?.[`name_${language}`] :
        unit?.[`changed_name_${language}`];
    case GRAMS_VALUE:
      return weight === KILOGRAM ?
        unit?.[`name_${language}`] :
        unit?.[`changed_name_${language}`];
    default:
      return unit?.[`name_${language}`];
  }
}

export const changeWeightValue = (value, weight) => {
  if(weight === KILOGRAM)
    return value/KILOGRAM_IN_GRAMS;
  return value;
}

export const changeVolumeValue = (value, volume) => {
  if(volume === MILLILITER)
    return value;
  return value/KILOGRAM_IN_GRAMS;
}

export const changeWeightValueToGrams = (value, weight) => {
  if(weight === KILOGRAM)
    return value * KILOGRAM_IN_GRAMS;
  return value;
}

export const changeVolumeValueToMilliliters = (value, volume) => {
  if(volume === MILLILITER)
    return value.toString();
  return (value * KILOGRAM_IN_GRAMS).toString();
}