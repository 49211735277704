import React from 'react';
import styles from "./styles.module.scss";
import { ReactComponent as CupIcon } from "assets/images/cup.svg";
import TitleSubtitle from 'components/titlie-subtitle';
import CONFIG from 'config/configURL';

const QuantitySliderItem = ({ subtitle, text, icon }) => {
    return (
        <div className={styles.main}>
            {icon ? 
                <img src={`${CONFIG.imageBaseUrl}${icon}`} alt="Item icon" className={styles.icon} /> :
                <CupIcon />
            }
            <TitleSubtitle
                subtitle={subtitle}
                text={text}
                subtitleClassName={styles.subtitle}
                textClassName={styles.text}
            />
        </div>
    )
}

export default QuantitySliderItem