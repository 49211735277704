/* eslint-disable react-hooks/exhaustive-deps */
import { VOLUME, WEIGHT } from "Constants";
import { useGetList } from "hooks";
import React, { useState, createContext, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import * as Api from "service";

export const StoreContext = createContext();

export const Store = (props) => {
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [remove, setRemove] = useState(null);
  const [user, setUser] = useState(null);
  const [lang, setLang] = useState("en");
  const [section, setSection] = useState("");
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "en"
  );
  const [inventoryIds, setInventoryIds] = useState([]);
  const [settings, setSettings] = useState([]);
  const [weightValue, setWeightValue] = useState("");
  const [volumeValue, setVolumeValue] = useState("");
  const [inventoryModal, setInventoryModal] = useState(false);
  const [goodInInventoryRemove, setGoodInInventoryRemove] = useState(false);

  const location = useLocation();

  const [settingsData, , refresh] = useGetList(Api.settings.getAll);

  useEffect(() => {
    if (!location.pathname.includes("/dashboard/inventories/"))
      setInventoryIds([]);
  }, [location.pathname]);

  useEffect(() => {
    if (settingsData?.length) {
      setSettings(settingsData);

      settingsData.forEach((elem) => {
        if (elem?.key === WEIGHT) {
          setWeightValue(elem?.value);
        }
        if (elem?.key === VOLUME) {
          setVolumeValue(elem?.value);
        }
      });
    }
  }, [settingsData]);

  useEffect(() => {
    if (!!user && Array.isArray(settingsData) && !settingsData?.length) {
      refresh();
    }
  }, [user, settingsData]);

  return (
    <StoreContext.Provider
      value={{
        successMsg,
        setSuccessMsg,
        errorMsg,
        setErrorMsg,
        remove,
        setRemove,
        user,
        setUser,
        lang,
        setLang,
        section,
        setSection,
        language,
        setLanguage,
        inventoryIds,
        setInventoryIds,
        settings,
        weightValue,
        volumeValue,
        refresh,
        setInventoryModal,
        inventoryModal,
        goodInInventoryRemove,
        setGoodInInventoryRemove,
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};

export default () => useContext(StoreContext);
