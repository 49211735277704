import React, { useCallback } from 'react';
import { Container, TextField, Card, Box, Switch, FormControlLabel } from '@material-ui/core';
import Gallery from '../../../components/gallery';

import {
  useText,
  useGetData,
  usePutData,
  usePostData, useGetList
} from '../../../hooks';
import useStore from '../../../context';

import * as Api from '../../../service';
import {List, Lang, Save, Select, MultiSelect} from '../../../components';
import { useParams } from 'react-router-dom';

const initForm = {
  id: '',
  active: '',
  branches: [],
};

const Form = () => {
  const { id } = useParams();
  const [data, , refresh] = useGetData(
    id && Api.managers.getById,
    id
  );
  const postCallback = usePostData(Api.managers.create, refresh);
  const updateCallback = usePutData(Api.managers.update, refresh);
  const { setErrorMsg } = useStore();

  const [text, setText, , input] = useText(data || initForm);
  const [ branches ] = useGetList(Api.branches.getAll);
  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      id ? updateCallback(text) : postCallback(text);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>

          <div className="my-3 py-3">
            <FormControlLabel
                style={{marginLeft: 0}}
                control={<Switch
                    checked={text.active}
                    onClick={()=>setText((st) => ({...st, active: !st.active}))}
                    className="switch-medium"
                    color="primary"
                    name="checkedB"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />}
                label={<span style={{marginLeft: '10px'}}>user active state</span>}
            />
          </div>

          {
            branches && <>
              <MultiSelect
                  noCard
                  className='w-100'
                  value={text.branches}
                  onChange={val => setText(st => ({ ...st, branches: val }))}
                  data={branches}
                  label='Select branch'
                  field="name"
              />
            </>
          }
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
