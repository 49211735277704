import React, { useCallback, useEffect, useState } from "react";
import { WrapperSeamless } from "layout-components";
import Table from "components/tables/table";
import { Avatar, Button } from "@material-ui/core";
import { useGetList } from "hooks";
import { Select } from "components";
import { Link, useLocation } from "react-router-dom";
import * as Api from "service";
import useStore from "context";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { useTranslation } from "localization/translation";
import {
  changeVolumeValue,
  getMeasurementName,
} from "helpers/getMeasurementName";
import { FIRST_PAGE, MANAGER_ROLE, PAGE, SIMPLE_COUNT } from "Constants";
import { MEASUREMENT_UNITS } from "../constants";

const imageBase = process.env.REACT_APP_imagesBaseUrl;
const noLimit = { limit: 0 };
const hideSearch = true;

export default function HomeClients() {
  const {
    setRemove,
    language,
    user,
    weightValue,
    volumeValue,
    setGoodInInventoryRemove,
  } = useStore();
  const translation = useTranslation();
  const [typesArray, setTypesArray] = useState();
  const [query, setQuery] = useState({
    sort: "created_at",
    order: "desc",
    type: "",
  });

  const limit = 20;

  const apiCall = user.role === MANAGER_ROLE ? Api.goodsManager : Api.goods;
  const apiCallTypes =
    user.role === MANAGER_ROLE ? Api.goodsTypeManager : Api.goodsType;

  const [types] = useGetList(apiCallTypes.getAll, noLimit, 0, hideSearch);
  const [items, total, refresh, setItems] = useGetList(
    apiCall.getAll,
    query,
    limit
  );

  const queryUrl = new URLSearchParams(useLocation());

  const callback = useCallback(() => {
    if (apiCall.getAll) {
      apiCall
        .getAll({
          page: (queryUrl.get(PAGE) || FIRST_PAGE) - SIMPLE_COUNT,
          limit: total,
        })
        .then((res) => setTypesArray(res.data));
    }
  });

  const handleDeleteGood = (id) => {
    Api.main
      .checkGoodInInventory(id)
      .then((res) => {
        if (res) {
          setGoodInInventoryRemove(true);
          setRemove(() => () => apiCall.delete(id).then(refresh));
        } else {
          setGoodInInventoryRemove(false);
          setRemove(() => () => apiCall.delete(id).then(refresh));
        }
      })
      .catch(() => {
        setGoodInInventoryRemove(false);
        setRemove(() => () => apiCall.delete(id).then(refresh));
      });
  };

  useEffect(callback, [query]);

  const rows = items?.map((item) => (
    <>
      <td className="d-flex">
        <Avatar
          className="mr-0"
          alt={item?.name_en}
          src={item?.image ? `${imageBase}${item?.image}` : imageBase}
        />
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {`${item[`name_${language}`]} (${getMeasurementName(
            item?.measurement_unit,
            language,
            weightValue,
            volumeValue
          )})`}
        </div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.barcode}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">
          {item?.measurement_unit === MEASUREMENT_UNITS.litrage && item?.litrage
            ? changeVolumeValue(item?.litrage, volumeValue)
            : "-"}
        </div>
      </td>
      <td className="text-right">
        <Link to={`/dashboard/goods/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>

        <Button
          onClick={() => handleDeleteGood(item.id)}
          className="btn-neutral-danger mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center"
        >
          <DeleteIcon color="danger" />
        </Button>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        noDrag
        title={translation.goods}
        items={items}
        setItems={setItems}
        total={total}
        fields={
          <>
            <th className="text-left px-4">
              <span className="mr-3">{translation.image}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.name}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.barcode}</span>
            </th>
            <th className="text-left px-4">
              <span className="mr-3">{translation.bottle_volume}</span>
            </th>
          </>
        }
        header={
          types && (
            <Select
              onChange={(val) => {
                setQuery((st) => ({ ...st, type: val }));
              }}
              value={query.type}
              field={`name_${language}`}
              label={translation.good_type}
              noCard
              options={types.length !== 0 ? types : typesArray}
              className="ml-4 mr-auto w-25"
            />
          )
        }
        rows={rows}
        createLink="dashboard/goods/create"
        limit={limit}
      />
    </WrapperSeamless>
  );
}
