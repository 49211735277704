

import Base from './base';

class Sign extends Base {
  getSignUp = (data) => this.postRequest('/client/register/company', data);
  logIn = (data) => this.postRequest('/client/dashboard-login', data);
  getUser = () => this.getRequest(`/client/get-user`);
  resetPassword = (query) => this.postRequest(`/client/forgot`, query);
  clientContinue = (query) => this.postRequest(`/client/register/continue`, query);
  resetPasswordConfirm = (token, data) => this.postRequest(`/client/password/reset/${token}`, data);
  tokenCheck = (token) => this.getRequest(`/client/token/check/${token}`);
  invitationCheck = (token) => this.getRequest(`/client/invitation/check/${token}`);
  invitationActivation = (token, data) => this.postRequest(`/client/invitation/${token}`, data);
  emailVerify = (token) => this.getRequest(`/client/verify/email/${token}`);
  sign_up = (data) => this.postRequest('/client/register', data);

}
export default new Sign();
