import React, {useEffect, useState} from "react"
import styles from "./styles.module.scss"
import useStore from "../../context";
import * as Api from "../../service";

const HelpingBars = () => {
  const imageBaseUrl = process.env.REACT_APP_imagesBaseUrl;
  const { language } = useStore();
  const [clientsSection, setClientsSection] = useState({})
  useEffect(() => {
    Api.main.getClientSection()
      .then(({ data }) => {
        setClientsSection(data);
      })
  },[]);
    const logos = [1,2,3,4,5,6]
    return (
        <div className={styles.main}>
            <h4 className={styles.title}>{clientsSection?.[`title_${language}`]}</h4>
            <div className={styles.logo_section}>
                {logos.map((i) => {
                  if (clientsSection?.[`client${i}_image`]) {
                    return (
                      <div className={styles.logo_div} key={i}>
                          <img
                            src={`${imageBaseUrl}${clientsSection?.[`client${i}_image`]}`}
                            alt={`${clientsSection?.[`client${i}_name`]}`}
                          />
                          <p>{`${clientsSection?.[`client${i}_name`]}`}</p>
                      </div>
                )}})}
           </div>
        </div>
    )
}

export default HelpingBars
