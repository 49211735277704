import React, { useEffect, useState } from "react"
import styles from "./styles.module.scss"
import TitleSubtitle from "../titlie-subtitle"
import * as Api from "../../service";
import useStore from "../../context"
import { ReactComponent as SquaresImage } from "assets/images/squares.svg"
import { ReactComponent as SquaresMobImage } from "assets/images/squaresMob.svg"
import { ReactComponent as WhiteAppStoreLogo } from "assets/images/AppStoreWhite.svg"
import { ReactComponent as WhiteGooglePlayLogo } from "assets/images/GooglePlayWhite.svg"

const LandingUpperSection = () => {
  const { language } = useStore();
  const [slider, setSlider] = useState({});
  useEffect(() => {
    Api.main.getSlider()
      .then(( { data }) => {
        setSlider(data)
      })
  }, [])
    return (
        <div id="about_us" className={styles.main_section}>
            <div className={styles.left_side}>
                <TitleSubtitle
                    title={slider?.[`secondary_title_${language}`]}
                    subtitle={slider?.[`title_${language}`]}
                    text={slider?.[`text_${language}`]}
                    subtitleClassName={styles.subtitle}
                    textClassName={styles.text}
                />
                <div className={styles.logos}>
                  <a href={slider?.app_store}>
                    <WhiteAppStoreLogo />
                  </a>
                  <a href={slider?.play_market}>
                    <WhiteGooglePlayLogo />
                  </a>
                </div>
            </div>
            <div className={styles.decSquares}>
              <SquaresImage className={styles.image} />
            </div>
            <div className={styles.mobSquares}>
              <SquaresMobImage />
            </div>
        </div>
    )
}

export default LandingUpperSection
