import Base from "./base";

class Main extends Base {
  login = (data) => this.postRequest("/auth/dashboard-login", data);
  logOff = () => this.getRequest("/auth/logoff");
  getuser = () => this.getRequest("/auth/getUser");
  mainSlider = () => this.getRequest("/web/home/slider");
  faq = () => this.getRequest("/web/home/faq");
  aboutContent = () => this.getRequest("/web/home/about/content");
  updatePassword = (data) => this.putRequest("/auth/change-password", data);
  refreshToken = () => this.getRequest("/auth/refresh");
  uploadFile = (file) => this.postRequest(`/files/upload`, file);
  getSlider = () => this.getRequest("/web/v2/home/page/slider");
  getBenefits = () => this.getRequest("/web/v2/home/page/benefits");
  getSteps = () => this.getRequest("/web/v2/home/page/steps");
  getImageSection = () =>
    this.getRequest("/web/v2/home/page/info-image-section");
  getItemsSection = () =>
    this.getRequest("/web/v2/home/page/info-items-section");
  getClientSection = () => this.getRequest("/web/v2/home/page/clients-section");
  getMobileSection = () => this.getRequest("/web/v2/home/page/mobile-section");
  getManagementSection = () =>
    this.getRequest("/web/v2/home/page/management-section");
  getContactSection = () =>
    this.getRequest("/web/v2/home/page/contact-section");
  getFAQSection = () => this.getRequest("/web/home/faq");
  getFooterSection = () => this.getRequest("/web/v2/home/page/footer-section");
  requestCallback = (data) => this.postRequest("/web/v2/home/contact", data);
  editProfile = (data) => this.putRequest("/client/user/profile", data);
  getProfile = (data) => this.getRequest("/client/user/profile");
  setUserBranchAction = ({ id, data }) =>
    this.postRequest(`/client/branches/change-branch-status/${id}`, data);
  iikoSync = (type) =>
    this.postRequest(`/v2/integration/iiko/sync?type=${type || "good"}`);
  deleteIikoProducts = () =>
    this.postRequest("/v2/integration/iiko/clear-items");
  checkIikoIntegration = () => this.getRequest("/v2/integration/iiko/ping");
  checkIikoConnection = (data) =>
    this.postRequest("/v2/integration/iiko/check-iiko-connection", data);
  changeLanguage = (data) =>
    this.putRequest("/v2/client/language/change", data);
  checkGoodInInventory = (id) =>
    this.getRequest(`/v2/client/goods/is-in-inventory/${id}`);
}

export default new Main();
