import React from "react";
import styles from "./styles.module.scss";
import { ReactComponent as NotallowedIcon } from "assets/images/notallowed.svg";
import CONFIG from "config/configURL";

const Adventages = ({ title, description, icon }) => {
    return (
        <div className={styles.main_section}>
            <div className={styles.text}>
                {icon ? 
                    <img src={`${CONFIG.imageBaseUrl}${icon}`} className={styles.icon}/> :
                    <NotallowedIcon />
                }
                <div className={styles.text_div}>
                    <h6 className={styles.title}>{title}</h6>
                    <p className={styles.description}>{description}</p>
                </div>
            </div>
        </div>
    )
}

export default Adventages