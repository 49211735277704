import Base from './base';

class Resource extends Base {
  constructor(path) {
    super();
    this.getAll = (query) => this.getRequest(path, query);
    this.getById = (id, query) => this.getRequest(`${path}/${id}`, query);
    this.create = (data) => this.postRequest(path, data);
    this.update = (data) => this.putRequest(path, data);
    this.updateList = (data) => this.putRequest(`${path}/status`, data);
    this.sort = (data) => this.putRequest(`${path}/sort`, data);
    this.delete = (id) => this.deleteRequest(`${path}/${id}`);
    this.resend = (id) => this.getRequest(`${path}/resend/${id}`);
  }
}

export default Resource;
