import React from "react";
import { Button, Dialog } from "@material-ui/core";
import { useTranslation } from "localization/translation";

export const SimplePopup = ({ setOpen, open, onClickHandler, name, text }) => {
  const translation = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      classes={{ paper: "shadow-lg rounded" }}
    >
      <div className="text-center p-5">
        <h4 className={`font-weight-bold mt-4`}>{name}</h4>
        <p>{text}</p>
        <div className="pt-4">
          <Button
            onClick={() => setOpen(false)}
            className="btn-neutral-secondary btn-pill mx-1"
          >
            <span className="btn-wrapper--label">{translation.cancel}</span>
          </Button>
          <Button onClick={onClickHandler} className="btn-success">
            <span className="btn-wrapper--label btn-action text-capitalize">
              {translation.confirm}
            </span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
