import React, { useState } from 'react';
import { WrapperSeamless } from '../../../layout-components';
import Table from '../../../components/tables/table';
import { Button } from '@material-ui/core';
import { useGetList } from '../../../hooks';
import { Link } from 'react-router-dom';
import * as Api from '../../../service';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'localization/translation';

export default function List() {
  const translation = useTranslation();
  const [query, setQuery] = useState({
    sort: 'created_at',
    order: 'desc',
  });


  const [items, total, refresh, setItems] = useGetList(
    Api.barmens.getAll,
    query
  );

    const rows = items?.map((item) => (
    <>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.name}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.surname}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.phone}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.email}</div>
      </td>
      <td className="text-left px-4">
        <div className="font-size-sm">{item.active ? translation.yes : translation.no}</div>
      </td>
      <td className="text-right">
        <Link to={`/dashboard/barmen/create/${item.id}`}>
          <Button className="btn-neutral-first mx-1 shadow-none d-30 border-0 p-0 d-inline-flex align-items-center justify-content-center">
            <EditIcon color="primary" />
          </Button>
        </Link>
      </td>
    </>
  ));

  return (
    <WrapperSeamless>
      <Table
        fields={
          <>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.first_name}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.last_name}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.phone}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.email}</span>
            </th>
            <th className="text-left px-4" >
              <span className="mr-3">{translation.active}</span>
            </th>
          </>
        }
        noDrag
        title={translation.items}
        items={items}
        setItems={setItems}
        total={total}
        rows={rows}
        createLink="dashboard/barmen/create"
      />
    </WrapperSeamless>
  );
}
