import React from "react";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';


const GreenCheckbox = withStyles({
  root: {
    color: '#C7C7CC',
    '&$checked': {
      color: '#05796B',
    },
    '& .MuiSvgIcon-root': {
      fontSize: '25px',
    }
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: '-9px',
    '& > span:last-child': {
      paddingTop: '9px !important',
    },
    '& .MuiTypography-root': {
      '& a': {
        color: '#05796B'
      }
    }
  }
});
const CheckboxComponent = ({ label, onChange }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      className={classes.root}
      label={<Typography style={{
          fontFamily: 'Proxima Nova Regular',
          fontSize: '16px',
          lineHeight: '21px',
          letterSpacing: '-0.32px',
          color: '#1C1C1E',
        }}>
          {label}
        </Typography>
      }
      control={
        <GreenCheckbox onChange={onChange} />
      }
    />
  )
};

export default CheckboxComponent;
