import { useCallback } from "react";
import useStore from "context";
import { useHistory } from "react-router-dom";
import {
  OLD_PASSWORD_ERROR,
  CONFIRMATION_PASSWORD_ERROR,
  OLD_PASSWORD,
  CONFIRMATION_PASSWORD,
  ERROR_MESSAGE,
  FUNCTION,
  SUCCESS_MESSAGE,
} from "Constants";
import { useTranslation } from "localization/translation";
import { getError } from "helpers/formValidation";

export const usePutData = (Api, refresh, route = true, dontShowMsg) => {
  const history = useHistory();
  const translation = useTranslation();
  const { setSuccessMsg, setErrorMsg } = useStore();

  return useCallback(
    async (data, id) => {
      try {
        await Api(data, id);
        if (typeof refresh === FUNCTION) {
          refresh();
        }
        if (!dontShowMsg) {
          setSuccessMsg(translation.updated);
        }
        if (route) history.goBack();
      } catch (e) {
        const param = e?.content?.[0]?.param;
        if (!dontShowMsg) {
          if (param === OLD_PASSWORD) {
            setErrorMsg(OLD_PASSWORD_ERROR);
          } else if (param === CONFIRMATION_PASSWORD) {
            setErrorMsg(CONFIRMATION_PASSWORD_ERROR);
          } else {
            setErrorMsg(e.message || getError(e) || ERROR_MESSAGE);
          }
        }
      }
    },
    [Api, refresh, setErrorMsg, setSuccessMsg]
  );
};
