import { useState, useCallback, useEffect } from "react";
import useStore from "context";
import {
  changeVolumeValue,
  changeWeightValue,
} from "helpers/getMeasurementName";

export const useText = (initialText, isGoods) => {
  const [text, setText] = useState(initialText);
  const [error, setError] = useState({});
  const { weightValue, volumeValue } = useStore();

  const textChange = useCallback((e) => {
    const { name, value } = e.target;
    setText((text) => ({ ...text, [name]: value }));
  }, []);

  useEffect(() => {
    if (isGoods) {
      const data = {
        ...initialText,
        weight_full: changeWeightValue(initialText?.weight_full, weightValue),
        weight_empty: changeWeightValue(initialText?.weight_empty, weightValue),
        litrage: changeVolumeValue(initialText?.litrage, volumeValue),
      };
      setText(data);
    } else {
      setText(initialText);
    }
  }, [initialText, isGoods, volumeValue, weightValue]);

  return [
    text,
    setText,
    textChange,
    (field) => ({
      name: field,
      value: text[field] || "",
      variant: "outlined",
      className: "my-2",
      fullWidth: true,
      onChange: textChange,
    }),
    error,
    setError,
  ];
};
