import React from "react";
import { WrapperSeamless } from "../../layout-components";
import { useGetList } from "../../hooks";
import * as Api from "../../service";
import logo from "assets/logo.svg";
import LinkIcon from "assets/icons/link.png"

const baseUrl = process.env.REACT_APP_imagesBaseUrl;
export default function Support() {
  const [items] = useGetList(Api.support.getAll);

  return (
    <WrapperSeamless>
      <div className="messengers-container">
        <div className="logo-wrapper">
          <img src={logo} alt="Logo" className="logo" />
        </div>

        {items?.map((item) => (
          <a href={item.link} className="messenger-item" key={item.id}>
            <span>
              <img className="messenger-icon" src={`${baseUrl}${item.icon}`} alt="Icon" />
              <p>{item.title}</p>
            </span>
            <img className="link-icon" src={LinkIcon} alt="Icon" />
          </a>
        ))}
      </div>
    </WrapperSeamless>
  );
}
