/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from "react";
import { Container, TextField, Card, Box } from "@material-ui/core";

import { useText, useGetData, usePutData, usePostData } from "hooks";
import useStore from "context";

import * as Api from "service";
import { Save } from "components";
import { useParams, useHistory } from "react-router-dom";
import { useTranslation } from "localization/translation";
import { MANAGER_ROLE } from "../constants";
import {
  NAME_EN,
  NAME_HY,
  NAME_RU,
  PATHNAMES,
  REQUIRED_ERROR_MESSAGE,
} from "Constants";

const initForm = {
  name_en: "",
};

const Form = () => {
  const { id } = useParams();
  const { setErrorMsg, user } = useStore();
  const translation = useTranslation();
  const history = useHistory();

  const apiCall =
    user.role === MANAGER_ROLE ? Api.goodsTypeManager : Api.goodsType;

  const [data, , refresh] = useGetData(id && apiCall.getById, id);
  const postCallback = usePostData(apiCall.create, refresh);
  const updateCallback = usePutData(apiCall.update, refresh);

  const [text, setText, , input, error, setError] = useText(data || initForm);

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      setError({});

      const errors = {
        [NAME_EN]: !text[NAME_EN],
      };

      if (Object.values(errors).some((hasError) => hasError)) {
        setError(errors);
        return;
      }

      const data = { ...text, name_hy: text.name_en, name_ru: text.name_en };

      id ? updateCallback(text) : postCallback(data);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  const validateField = (field, value) => {
    let isValid = true;

    switch (field) {
      case NAME_EN:
        isValid = !!value;
        break;
      default:
        isValid = true;
    }

    setError((prevErrors) => ({
      ...prevErrors,
      [field]: !isValid,
    }));
  };

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setText((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  useEffect(() => {
    if (data && data?.related_good_type_id) {
      history.push(PATHNAMES.good_types);
    }
  }, [data]);

  return id ? (
    !!data && (
      <Container>
        <Card className="shadow-xxl px-4 py-2">
          <Box component="form" onSubmit={submitForm}>
            <TextField
              {...input("name_en")}
              label={`${translation.name}*`}
              error={!!error?.name_en}
              helperText={error?.name_en && REQUIRED_ERROR_MESSAGE}
              onChange={handleTextChange}
            />
            <Save />
          </Box>
        </Card>
      </Container>
    )
  ) : (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            {...input("name_en")}
            label={`${translation.name}*`}
            error={!!error?.name_en}
            helperText={error?.name_en && REQUIRED_ERROR_MESSAGE}
            onChange={handleTextChange}
          />
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
